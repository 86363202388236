<template>
  <div class="modal fade modal-bookmark" id="addCandidate" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header py-3">
          <h5 class="modal-title">Add candidate</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="form-bookmark needs-validation">
            <div class="row mb-2">
              <div class="col">
                <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul class="txt-danger">
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                  </ul>
                </p>
              </div>
            </div>
            <div class="row mb-2">
              <div class="form-group col-md-6">
                <label>First Name</label>
                <input class="form-control" type="text" autocomplete="off" v-model="candidate.first_name" />
              </div>
              <div class="form-group col-md-6">
                <label>Last Name</label>
                <input class="form-control" type="text" autocomplete="off" v-model="candidate.last_name" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="form-group col-md-12">
                <label>Email</label>
                <input class="form-control" type="email" autocomplete="off" v-model="candidate.email" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="form-group col-md-12">
                <label>Birthdate</label>
                <input class="form-control" type="date" autocomplete="off" v-model="candidate.birth_date" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="form-group col-md-12">
                <label>Address</label>
                <input class="form-control" type="text" autocomplete="off" v-model="candidate.address" />
              </div>
            </div>
            <div class="form-row"></div>
            <br />
            <button class="btn btn-secondary" type="button" @click="checkForm">Save</button>
            <button class="btn btn-primary me-2" ref="Close" type="button" data-bs-dismiss="modal">Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'AddCandidate',
  data() {
    return {
      candidate: {},
      errors: [],
    };
  },
  methods: {
    ...mapActions(['addCandidate']),
    checkForm: function (e) {
      if (this.candidate.first_name && this.candidate.email) {
        this.addCandidate(this.candidate).then(res => {
            this.$refs.Close.click();
        });
        return true;
      }

      this.errors = [];

      if (!this.candidate.first_name) {
        this.errors.push('Name required.');
      }
      if (!this.candidate.email) {
        this.errors.push('Email required.');
      }

      e.preventDefault();
    },
  },
};
</script>
