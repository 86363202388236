import { candidatesService } from '../../_services';

const state = {
  isLoadingCandidates: false,
  listCandidates: [],
  listPassedCandidates: [],
  listPendingCandidates: [],
  selectedCandidate: '',
  selectedTestCandidate: '',
  selectedCandidates: [],
};
// getters
const getters = {
  isLoadingCandidates: (state) => state.isLoadingCandidates,
  selectedCandidate: (state) => state.selectedCandidate,
  selectedTestCandidate: (state) => state.selectedTestCandidate,
  allCandidate: (state) => state.listCandidates,
  allPassedCandidates: (state) => state.listPassedCandidates,
  allPendingCandidates: (state) => state.listPendingCandidates,
  errorsCandidates: (state) => state.errors,
  selectedCandidates: (state) => state.selectedCandidates,
};
const actions = {
  async getAllCandidates({ commit }, params) {
    commit('setLoadingStatus', true);
    await candidatesService.getAllCandidates({ params }).then(
      (candidates) => {
        commit('getAllSuccess', candidates.data.data);
        commit('setLoadingStatus', false);
      },
      (error) => commit('getAllFailure', error)
    );
  },
  exportCandidates({ commit }, params) {
    return candidatesService.exportCandidatesList({ params }).then(
      (candidates) => candidates,
      (error) => {
        return false;
      }
    );
  },
  async sendInvitationCandidate({ commit }, params) {
    return await candidatesService.invitCandidate(params).then(
      (candidate) => {
        return candidate;
      },
      (error) => {
        return error;
      }
    );
  },
  async assignTestToCandidate({ commit }, params) {
    return await candidatesService.assignTest(params).then(
      (candidate) => {
        return candidate;
      },
      (error) => {
        return error;
      }
    );
  },
  async addCandidate({ commit }, payload) {
    return await candidatesService.addCandidate(payload).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          commit('addCandidateSuccess', res.data);
        }
        return res.data;
      },
      (error) => {
        return error;
      }
    );
  },
  async editCandidate({ commit }, { idCandidate, params }) {
    return await candidatesService.updateCandidate(idCandidate, params).then(
      (candidate) => {
        return candidate;
      },
      (error) => {
        return error;
      }
    );
  },
  async deleteCandidate({ commit }, idCandidate) {
    return await candidatesService.deleteCandidate(idCandidate).then(
      (res) => {
        if (res.status === 200) {
          commit('removeCandidateFromList', idCandidate);
        }
        return res;
      },
      (error) => {
        return error;
      }
    );
  },
  async deleteTestCandidate({ commit }, idQuestion) {
    return await candidatesService.deleteTestCandidate(idQuestion).then(
      (res) => {
        return res;
      },
      (error) => {
        return error;
      }
    );
  },

  async getResultTestCandidate({ commit }, idTest) {
    return await candidatesService.getResultCandidate(idTest).then(
      (res) => {
        return res.data;
      },
      (error) => {
        return error;
      }
    );
  },
  async sendMultiInvitationCandidates({ commit }, params) {
    return await candidatesService.sendInvitationCandidates(params).then(
      (candidate) => {
        return candidate;
      },
      (error) => {
        return error;
      }
    );
  },
};
const mutations = {
  addCandidateSuccess(state, candidate) {
    state.listCandidates.push(candidate);
  },
  removeCandidateFromList(state, idCandidate) {
    const objWithIdIndex = state.listCandidates.findIndex((obj) => obj.id === idCandidate);
    if (objWithIdIndex > -1) {
      state.listCandidates.splice(objWithIdIndex, 1);
    }
  },
  getAllSuccess(state, modules) {
    state.listCandidates = modules;
    this.commit('filterCandidate', state.listCandidates);
  },
  filterCandidate(state, listCandidates) {
    state.listPassedCandidates = listCandidates.filter((passedCandidate) => passedCandidate.status === 1);
    state.listPendingCandidates = listCandidates.filter((pendingCandidate) => pendingCandidate.status === 2);
  },
  selectAllCandidate(state, selectedAllValue) {
    if (selectedAllValue) {
      state.listCandidates = state.listCandidates.map((v) => ({ ...v, isActive: true }));
      state.selectedCandidates = state.listCandidates.map((a) => a.id);
    } else {
      state.listCandidates = state.listCandidates.map((v) => ({ ...v, isActive: false }));
      state.selectedCandidates = [];
    }
    this.commit('filterCandidate', state.listCandidates);
  },
  checkedCandidate(state, idCandidate) {
    const objWithIdIndex = state.selectedCandidates.findIndex((obj) => obj === idCandidate);
    const indexCandidate = state.listCandidates.findIndex((candidate) => candidate.id === idCandidate);
    if (objWithIdIndex > -1) {
      state.selectedCandidates.splice(objWithIdIndex, 1);
      state.listCandidates[indexCandidate].isActive = false;
    } else {
      state.listCandidates[indexCandidate].isActive = true;
      state.selectedCandidates.push(idCandidate);
    }
    this.commit('filterCandidate', state.listCandidates);
  },
  getAllFailure(state, error) {
    state.errorsCandidate = { error };
  },
  selectCandidate(state, candidate) {
    state.selectedCandidate = candidate;
  },
  selectTestCandidate(state, test) {
    state.selectedTestCandidate = test;
  },
  setLoadingStatus(state, status) {
    state.isLoadingCandidates = status;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
