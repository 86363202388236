import { userService } from '../../_services';

const state = {
  listUsers: [],
  isLoadUsers: false,
};
// getters
const getters = {
  allUsers: (state) => state.listUsers,
  isLoadUsers: (state) => state.isLoadUsers,
  errorsUsers: (state) => state.errors,
};
const actions = {
  async getAllUsers({ commit }) {
    commit('isLoadStatus', true);
    await userService.getAllUsers().then(
      (res) => commit('getAllSuccess', res.data.data),
      (error) => commit('getAllFailure', error)
    );
  },
  async addUser({ commit }, payload) {
    return await userService.addUser(payload).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          commit('addCandidateSuccess', res.data);
        }
        return res.data;
      },
      (error) => {
        return error;
      }
    );
  },
  async deleteUser({ dispatch }, payload) {
    return await userService.deleteUser(payload).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          dispatch('getAllUsers');
        }
        return res;
      },
      (error) => {
        return error;
      }
    );
  },
  async updateUser({ dispatch }, payload) {
    const params = {
      name: payload.name,
      status: payload.status,
      email: payload.email
    };
    return await userService.updateUser(payload.id, params).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          dispatch('getAllUsers');
        }
        return res;
      },
      (error) => {
        return error;
      }
    );
  },
};
const mutations = {
  addUserSuccess(state, user) {
    state.listUsers.push(user);
  },
  getAllSuccess(state, users) {
    state.listUsers = users;
    state.isLoadUsers = false;
  },
  getAllFailure(state, error) {
    state.errorsUsers = { error };
  },
  isLoadStatus(state, isLoad) {
    state.isLoadUsers = isLoad;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
