import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'bootstrap/dist/js/bootstrap.bundle';
import '@/assets/scss/app.scss';
import VueFeather from 'vue-feather';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import fr from './locales/fr.json';
import { defaultLocale, localeOptions } from './constants/config';
import Breadcrumbs from './components/bread_crumbs';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import DropZone from 'dropzone-vue';
import Toaster from '@meforma/vue-toaster';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faArrowLeft,
  faArrowRight,
  faCheck,
  faEye,
  faFrown,
  faInfoCircle,
  faMeh,
  faMinus,
  faPaperPlane,
  faSmile,
  faTrash,
  faPlusCircle,
  faEllipsisH,
  faSquarePollVertical,
  faClock,
  faClipboardCheck,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(
  faArrowLeft,
  faArrowRight,
  faEye,
  faTrash,
  faPaperPlane,
  faInfoCircle,
  faCheck,
  faMinus,
  faFrown,
  faSmile,
  faMeh,
  faPlusCircle,
  faEllipsisH,
  faSquarePollVertical,
  faClock,
  faClipboardCheck
);

const optionsToast = {
  position: 'top-right',
  duration: 5000,
};
const messages = { en, fr };
const locale =
  localStorage.getItem('currentLanguage') &&
  localeOptions.filter((x) => x.id === localStorage.getItem('currentLanguage')).length > 0
    ? localStorage.getItem('currentLanguage')
    : defaultLocale;

const i18n = createI18n({
  locale: locale,
  messages: messages,
});

createApp(App)
  .use(Toaster, optionsToast)
  .use(router)
  .use(i18n)
  .use(store)
  .use(VueSweetalert2)
  .use(DropZone)
  .component(VueFeather.name, VueFeather)
  .component('Breadcrumbs', Breadcrumbs)
  .component('font-awesome-icon', FontAwesomeIcon)
  .mount('#app');
