import { API } from '../modules/axios';

const END_POINT = 'modules';
const END_POINT_LEVELS = 'levels';

const getAllModules = (params) => API.get(END_POINT, params);
const getModule = (idModule) => API.get(`${END_POINT}/${idModule}`);
const updateLevel = (idLevel, params) => API.put(`${END_POINT_LEVELS}/${params.keyModule}/${idLevel}`, params);
const getLevels = (params) => API.get(END_POINT_LEVELS, params);
const deleteModule = (idModule) => API.delete(`${END_POINT}/${idModule}`);
const addNewModule = (params) => API.post(`${END_POINT}`, params);
const addLevelModule = (params) => API.post(`${END_POINT_LEVELS}/${params.keyModule}`, params);
const deleteLevelFromModule = (keyModule, idLevel) => API.delete(`${END_POINT_LEVELS}/${keyModule}/${idLevel}`);

export const modulesService = {
  getAllModules,
  getModule,
  getLevels,
  updateLevel,
  deleteModule,
  addNewModule,
  addLevelModule,
  deleteLevelFromModule,
};
