<template>
  <div class="modal fade modal-bookmark multiSendInvitation_test" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header py-3">
          <h5 class="modal-title">Send invitaion to pass level test</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div v-for="(item, index) in countTests" :key="index" class="row mb-2">
            <div class="form-group col-md-6">
              <label>Modules</label>
              <select class="form-select" v-model="selectedModule[index]">
                <option v-for="(item, index) in modules" :key="index" :value="item">
                  {{ item.name }}
                </option>
              </select>
            </div>
            <div class="form-group col-md-6">
              <label>Test</label>
              <select class="form-select" v-model="selectedLevel[index]">
                <option v-for="(item, index) in selectedModule[index]?.levels" :key="index" :value="item">
                  {{ item.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col d-flex justify-content-end">
              <button class="btn btn-xs btn-warning" @click="addTestCandidate">
                <vue-feather class="me-1" size="14" type="plus-square"> </vue-feather> Add test
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4">
              <label>Opening Date & Time</label>
              <input
                class="form-control digits"
                id="example-datetime-local-input"
                type="datetime-local"
                v-model="openingDate"
              />
            </div>
            <div class="col-md-4">
              <label>Limit Date & Time</label>
              <input
                class="form-control digits"
                id="example-datetime-local-input"
                type="datetime-local"
                v-model="limitDate"
              />
            </div>
          </div>
          <div v-if="allCandidate">
            <h5 class="my-3 d-flex">
              <strong>You are sure to send an invitation to :</strong>
              <label for="send_emails" class="d-block mb-0 text-danger ms-3">
                <input
                  id="send_emails"
                  class="checkbox_animated"
                  type="checkbox"
                  value="true"
                  v-model="checkedSendEmail"
                />Yes
              </label>
            </h5>
            <p v-for="(candidate, index) in allCandidate" :key="index">
              <strong v-if="candidate.isActive">
                - {{ candidate.first_name }} {{ candidate.last_name }} : {{ candidate.email }}</strong
              >
            </p>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" @click="sendInvitation" :disabled="isSending">
            <vue-feather class="me-2" type="send" size="16"></vue-feather>
            {{ checkedSendEmail ? 'Assign and send mail' : 'Assign' }}
          </button>
          <button class="btn btn-primary ms-2" type="button" ref="Close" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'InvitMultiCandidate',
  props: ['modules'],
  data() {
    return {
      isSending: false,
      checkedSendEmail: false,
      selectedModule: [],
      selectedLevel: [],
      countTests: 1,
      openingDate: moment().format('YYYY-MM-DD HH:MM'),
      limitDate: moment().format('YYYY-MM-DD HH:MM'),
    };
  },
  computed: {
    ...mapGetters(['allCandidate', 'selectedCandidates']),
  },
  methods: {
    ...mapActions(['sendMultiInvitationCandidates']),
    addTestCandidate(e) {
      e.preventDefault();
      this.countTests += 1;
    },
    resetForm() {
      this.isSending = false;
      this.checkedSendEmail = false;
      this.selectedModule = [];
      this.selectedLevel = [];
      this.countTests = 1;
    },
    sendInvitation() {
      this.isSending = true;
      let tests = this.selectedModule.map((module, index) => {
        return {
          module_id: module.id,
          level_id: this.selectedLevel[index].id,
        };
      });

      let params = {
        tests,
        candidate_ids: this.selectedCandidates,
        send_invitation: this.checkedSendEmail,
        opening_date: this.openingDate,
        limit_date: this.limitDate,
      };

      this.sendMultiInvitationCandidates(params).then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.$toast.show(this.checkedSendEmail ? 'Email sent successfully' : 'Assign test successfully', {
            theme: 'outline',
            type: 'success',
          });
          this.$emit('refreshListCandidate');
        } else {
          this.$toast.show('Error ! Please try again later or contact the administrator', {
            theme: 'outline',
            type: 'error',
          });
        }
        this.$refs.Close.click();
        this.resetForm();
        this.isSending = false;
      });
    },
  },
};
</script>
