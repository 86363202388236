<template>
  <div class="row">
    <div class="col-lg-4 col-md-6" v-for="(item, index) in listCandidates" :key="index">
      <div class="project-box" :class="{ active: item.isActive }">
        <span class="badge badge-warning">{{ item.status == 2 ? 'Pending' : 'Passed' }}</span>
        <div class="d-flex mb-3">
          <img
            class="img-50 me-2 rounded-circle"
            src="../../../assets/images/user/user.png"
            height="50"
            @click="checkCandidate(item.id)"
          />
          <div class="infos">
            <div class="infos-actions d-flex">
              <h6>{{ item.first_name }} {{ item.last_name }}</h6>
              <a
                class="ms-3 text-info"
                href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target=".modal-editCandidate"
                @click="selectCandidate(item)"
              >
                <vue-feather type="edit" size="20"></vue-feather>
              </a>
              <a
                class="ms-2 text-danger"
                href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target=".modal-deleteCandidate"
                @click="selectCandidate(item)"
              >
                <vue-feather type="trash-2" size="20"></vue-feather>
              </a>
            </div>
            <div class="flex-grow-1 project-box-item">
              <p>{{ item.email }}<br />{{ item.birth_date }}</p>
            </div>
          </div>
        </div>
        <p>
          <strong> Assigned Tests: </strong>({{ item.tests ? item.tests?.length : 0 }})
          <button
            class="btn btn-info btn-xs ms-auto"
            href="javascript:void(0)"
            data-bs-toggle="modal"
            data-bs-target=".assign-test-toCandidate"
            @click="selectCandidate(item)"
          >
            <vue-feather class="me-1" type="plus" size="16"></vue-feather>Test
          </button>
        </p>
        <div class="tests-box-list">
          <div class="row details" v-for="(test, indexTest) in item.tests" :key="indexTest">
            <div class="col-7">
              <p>
                {{ test.test_details[0]?.module }}
                <span v-if="test.test_details[0]?.level">- {{ test.test_details[0]?.level }}</span>
                <a
                  v-if="test.test_details.length > 1"
                  data-container="body"
                  data-bs-toggle="tooltip"
                  data-bs-placement="top"
                  :title="getAlltestsCandidate(test.test_details)"
                  >&nbsp;<font-awesome-icon class="text-primary" :icon="['fas', 'ellipsis-h']" />
                </a>
              </p>
            </div>
            <div class="col-5 text-end fs-6">
              <router-link
                v-if="statusPassedResult.includes(test.status)"
                class="text-info"
                :to="{ name: 'ResultTest', params: { idTest: test.id } }"
                data-container="body"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Show Result"
              >
                <font-awesome-icon :icon="['fas', 'info-circle']" />
              </router-link>
              <router-link
                class="ms-2"
                :to="{ name: 'testCandidate', params: { token: test.connection_link } }"
                data-container="body"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Show Real Test"
              >
                <font-awesome-icon :icon="['fas', 'eye']" />
              </router-link>
              <a
                class="ms-2 text-danger"
                href="javascript:void(0)"
                @click="delete_alert(test.connection_link)"
                data-container="body"
                data-bs-toggle="tooltip"
                data-bs-placement="top"
                title="Delete Test"
              >
                <font-awesome-icon :icon="['fas', 'trash']" />
              </a>
              <a
                class="ms-2 text-warning"
                href="javascript:void(0)"
                data-bs-toggle="modal"
                data-bs-target=".sendInvitation_test"
                @click="selectTestCandidate(item, test)"
              >
                <font-awesome-icon :icon="['fas', 'paper-plane']" />
              </a>
            </div>
          </div>
        </div>
        <!-- <div class="project-status mt-4">
          <div class="d-flex mb-0">
            <p>{{ item.progress }}%</p>
            <div class="flex-grow-1 text-end"><span>Done</span></div>
          </div>
          <div class="progress" style="height: 5px">
            <div
              class="progress-bar-animated progress-bar-striped"
              :class="item.class"
              role="progressbar"
              :style="{ width: item.width }"
              aria-valuenow="10"
              aria-valuemin="0"
              aria-valuemax="100"
            ></div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { Tooltip } from 'bootstrap/dist/js/bootstrap.bundle';

export default {
  name: 'allPage',
  data() {
    return {
      statusPassedResult: [3, 4],
    };
  },
  mounted() {
    new Tooltip(document.body, {
      selector: "[data-bs-toggle='tooltip']",
    });
  },
  unmounted() {
    let popovers = document.getElementsByClassName('tooltip');
    popovers.forEach((item) => {
      item.remove();
    });
  },
  computed: {
    ...mapGetters({
      listCandidates: 'allPendingCandidates',
      allModules: 'allModules',
      isLoadModules: 'isLoadModules',
    }),
  },
  methods: {
    ...mapActions(['getAllCandidates', 'getAllModules', 'deleteTestCandidate']),
    getAlltestsCandidate(testDetails) {
      let tooltipText = '';
      testDetails.forEach((detail, index) => {
        if (index !== 0) {
          tooltipText += `${detail.module} ${detail.level ? ' - ' + detail.level : ''}`;
          if (index < testDetails.length - 1) {
            tooltipText += ' - ';
          }
        }
      });
      return tooltipText;
    },
    checkCandidate(id) {
      this.$store.commit('checkedCandidate', id);
    },
    refreshCandidates() {
      this.getAllCandidates();
    },
    delete_alert(item) {
      this.$swal({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this Test!',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#e64942',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#efefef',
      }).then((result) => {
        this.deleteTestCandidate(item).then((res) => {
          if (result.value && res.status === 200) {
            this.getAllCandidates();
            this.$toast.show('Delete Success', {
              theme: 'outline',
              type: 'warning',
            });
          }
        });
      });
    },
    selectCandidate(candidate) {
      this.$store.commit('selectCandidate', candidate);
    },
    selectTestCandidate(candidate, test) {
      this.$store.commit('selectCandidate', candidate);
      this.$store.commit('selectTestCandidate', test);
    },
  },
};
</script>
