<template>
  <Breadcrumbs main="Subjects" title="Subjects List" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header d-flex">
            <h5>Liste des Subjects</h5>
            <a
              class="btn btn-info ms-auto f-w-600 d-flex align-items-center"
              href="javascript:void(0)"
              data-bs-toggle="modal"
              data-bs-target="#addCandidate"
              ><vue-feather class="me-2" type="plus"></vue-feather>Add Subject</a
            >
            <AddSubject />
          </div>
          <div class="card-body">
            <div class="table-responsive product-table">
              <table class="display table" id="basic-1">
                <thead>
                  <tr>
                    <th
                      class="sorting"
                      tabindex="0"
                      aria-controls="basic-1"
                      rowspan="1"
                      colspan="1"
                      aria-label="Details: activate to sort column ascending"
                    >
                      Name
                    </th>

                    <th
                      class="sorting"
                      tabindex="0"
                      aria-controls="basic-1"
                      rowspan="1"
                      colspan="1"
                      aria-label="Action: activate to sort column ascending"
                    >
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="subject in allSubjects" :key="subject">
                    <td>
                      <strong>{{ subject.name }} </strong>
                    </td>
                    <td>
                      <!-- <button
                        class="btn btn-success btn-xs ms-1"
                        type="button"
                        data-original-title="btn btn-danger btn-xs"
                        title=""
                      >
                        <vue-feather class="me-2" type="edit" size="16"></vue-feather>Edit
                      </button> -->
                      <button
                        class="btn btn-danger btn-xs ms-1 py-1 px-2"
                        href="javascript:void(0)"
                        data-bs-toggle="modal"
                        data-bs-target=".modal-deleteSubject"
                        @click="selectSubject(subject)"
                      >
                        <vue-feather type="trash" size="14"></vue-feather>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade modal-deleteSubject"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <validation-modal
        :title="'Delete'"
        :message="'Voulez vous vraiment supprimer cet élément ?'"
        @validationModal="deleteSubjectValidation"
      />
    </div>
  </div>
</template>

<script>
import AddSubject from './addSubject.vue';
import { mapActions, mapGetters } from 'vuex';
import validationModal from '@/components/validationModal.vue';
export default {
  name: 'subjectsList',
  data() {
    return {
      selectedSubject: '',
    };
  },
  components: {
    AddSubject,
    validationModal,
  },
  computed: {
    ...mapGetters(['allSubjects']),
  },
  methods: {
    ...mapActions(['getAllSubjects', 'deleteSubject']),
    selectSubject(subject) {
      this.selectedSubject = subject;
    },
    deleteSubjectValidation(validation) {
      if (validation) {
        this.deleteSubject(this.selectedSubject.id).then(() => {
          this.$toast.show('Delete Success', {
            theme: 'outline',
            type: 'warning',
          });
        });
      }
      this.selectedSubject = '';
    },
  },
  created() {
    this.getAllSubjects();
  },
};
</script>
