<template>
  <div>
    <div class="container-fluid">
      <div class="page-title">
        <div class="row">
          <div class="col-6 d-flex">
            <h3 v-if="title">{{ title }}</h3>
          </div>
          <div class="col-6">
            <ol class="breadcrumb">
              <li class="breadcrumb-item">
                <router-link :to="{ path: '/' }">
                  <svg class="stroke-icon">
                    <use href="@/assets/svg/icon-sprite.svg#stroke-home"></use>
                  </svg>
                </router-link>
              </li>
              <li class="breadcrumb-item" v-if="main">{{ main }}</li>
              <li class="breadcrumb-item active" v-if="title">{{ title }}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      default: 'home',
    },
    main: {
      default: '',
    },
  },
  /* computed: {
    parentActiveMenu() {
      return this.$store.state.menu.data.find((data) => data.active);
    },
    main() {
      return this.parentActiveMenu?.title;
    },
    title() {
      return this.parentActiveMenu?.children?.find((data) => data.active)?.title;
    },
  }, */
};
</script>
