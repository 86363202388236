import { questionsService } from '../../_services';

const state = {
  isLoadTest: false,
  questionTypes: [],
  selectedQuestion: {},
  activeclass: 'pills-quest-0',
};
// getters
const getters = {
  isLoadTest: (state) => state.isLoadTest,
  selectedQuestion: (state) => state.selectedQuestion,
  questionTypes: (state) => state.questionTypes,
  errors: (state) => state.errors,
  activeclass: (state) => state.activeclass,
};
const actions = {
  active: (context, payload) => {
    context.commit('active', payload);
  },
  async getQuestions({ commit }, payload) {
    commit('setLoadingTest', true);
    return await questionsService.getQuestions(payload).then(
      (question) => {
        commit('getQuestionSuccess', question.data);
        commit('setLoadingTest', false);
        return question.data;
      },
      (error) => commit('getQuestionFailure', error)
    );
  },
  async getQuestionTypes({ commit }, payload) {
    return await questionsService.getQuestionTypes(payload).then(
      (questionTypes) => {
        commit('getQuestionTypesSuccess', questionTypes.data);
        return questionTypes.data;
      },
      (error) => commit('getQuestionTypesFailure', error)
    );
  },
  addParentQuestion({ commit }, newQuestion) {
    return questionsService.addQuestion(newQuestion).then(
      (question) => question,
      (error) => error
    );
  },
  addSubQuestion({ commit }, { idQuestion, newQuestion }) {
    return questionsService.updateQuestion(idQuestion, newQuestion).then(
      (module) => module,
      (error) => error
    );
  },
  async deleteQuestion({ commit }, payload) {
    return await questionsService.deleteQuestion(payload).then(
      (res) => {
        return res;
      },
      (error) => commit('getQuestionFailure', error)
    );
  },
  getQuestionsResultBack({ commit }, { idTest, params }) {
    return questionsService.sendQuestionsTest(idTest, params).then(
      (result) => {
        return result.data;
      },
      (error) => {
        return error;
      }
    );
  },
};
const mutations = {
  active: (state, payload) => {
    state.activeclass = payload;
  },
  getQuestionSuccess(state, question) {
    state.selectedQuestion = question;
  },
  getQuestionFailure(state, error) {
    state.errors = { error };
  },
  getQuestionTypesSuccess(state, questionTypes) {
    state.questionTypes = questionTypes;
  },
  getQuestionTypesFailure(state, error) {
    state.errors = { error };
  },
  setLoadingTest(state, load) {
    state.isLoadTest = load;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
