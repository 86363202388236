<template>
  <div class="modal fade modal-bookmark" id="addUserModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header py-3">
          <h5 class="modal-title">Add User</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="form-bookmark needs-validation">
            <!-- <div class="row mb-2">
              <div class="col">
                <p v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul class="txt-danger">
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                  </ul>
                </p>
              </div>
            </div> -->
            <div class="row mb-2">
              <div class="form-group col-md-6">
                <label>Name</label>
                <input class="form-control" type="text" autocomplete="off" v-model="user.name" />
              </div>
              <div class="form-group col-md-6">
                <label>Role</label>
                <select class="form-select" v-model="user.role">
                  <option v-for="(item, index) in roles" :key="index" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mb-2">
              <div class="form-group col-md-12">
                <label>Email</label>
                <input class="form-control" type="email" autocomplete="off" v-model="user.email" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="form-group col-md-12">
                <label>Password</label>
                <input class="form-control" type="password" autocomplete="off" v-model="user.birth_date" />
              </div>
            </div>
            <div class="row mb-2">
              <div class="form-group col-md-6">
                <label>Logo</label>
                <input class="form-control" type="file" @change="handleFileUploadMedia($event, 'logo')" />
              </div>
              <div class="form-group col-md-6">
                <label>Avatar</label>
                <input class="form-control" type="file" @change="handleFileUploadMedia($event, 'avatar')" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div v-if="user.logo" class="w-100 block-img">
                  <img :src="!logoEdited ? baseURL + user.logo : user.logo" class="img-fluid" />
                </div>
              </div>
              <div class="col-md-6">
                <div v-if="user.avatar" class="w-100 block-img">
                  <img :src="!avatarEdited ? baseURL + user.avatar : user.avatar" class="img-fluid" />
                </div>
              </div>
            </div>
            <div class="form-row"></div>
            <br />
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" @click="checkForm">Save</button>
          <button class="btn btn-primary ms-2" ref="Close" type="button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { baseURL } from '@/constants/config';
export default {
  name: 'AddUserModal',
  data() {
    return {
      user: {},
      errors: [],
      baseURL: baseURL,
      logoEdited: false,
      avatarEdited: false,
      roles: [
        { id: 1, name: 'Admin' },
        { id: 2, name: 'Assistant' },
        { id: 3, name: 'Teacher' },
      ],
    };
  },
  methods: {
    ...mapActions(['addUser']),
    handleFileUploadMedia(event, type) {
      const file = event.target.files[0];
      this.createBase64ImageMedia(file, type);
    },
    createBase64ImageMedia(fileObject, type) {
      const reader = new FileReader();
      reader.readAsDataURL(fileObject);
      reader.onload = () => {
        if (type === 'logo') {
          this.user.logo = reader.result;
          this.logoEdited = true;
        } else if (type === 'avatar') {
          this.user.avatar = reader.result;
          this.avatarEdited = true;
        }
      };
    },
    checkForm: function (e) {
      if (this.user.name && this.user.email) {
        this.addUser(this.user).then((res) => {
          this.$refs.Close.click();
        });
        return true;
      }

      this.errors = [];

      if (!this.user.name) {
        this.errors.push('Name required.');
      }
      if (!this.user.email) {
        this.errors.push('Email required.');
      }

      e.preventDefault();
    },
  },
};
</script>
