import { userService } from '../../_services';
import router from '../../router/index';
import Menu from '../../data/menu';
import MenuTeacher from '../../data/menu-teacher.json';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { status: { loggedIn: true }, user: user.user } : { status: {}, user: null };

const state = { user: initialState ? initialState : {} };

const actions = {
  async login({ dispatch, commit }, { username, password }) {
    commit('loginRequest', { username });
    return new Promise((resolve, reject) => {
      userService.login({ email: username, password: password }).then(
        (user) => {
          let userData = user.data;
          commit('loginSuccess', userData);
          dispatch('getAllModules');
          if (userData.access_token) {
            localStorage.setItem('user', JSON.stringify(userData));
          }
          if (userData.user.roles[0].name === 'teacher') {
            localStorage.setItem('menu', JSON.stringify(MenuTeacher));
            commit('menu/setMenu', MenuTeacher.data);
          } else if (userData.user.roles[0].name === 'manager') {
            commit('menu/setMenu', MenuTeacher.data);
            localStorage.setItem('menu', JSON.stringify(MenuTeacher));
          } else {
            commit('menu/setMenu', Menu.data);
            localStorage.setItem('menu', JSON.stringify(Menu));
          }
          router.push({ name: 'defaultRoot' });
          resolve();
        },
        (error) => {
          commit('loginFailure', error);
          reject();
        }
      );
    });
  },
  logoutUser({ commit }) {
    userService.logout();
    commit('logout');
    router.push({ name: 'LoginValidate' });
  },
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
};

const getters = {
  user: (state) => state.user,
};

export const authentication = {
  namespaced: true,
};

export default {
  state,
  getters,
  actions,
  mutations,
};
