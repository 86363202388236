import { createStore } from 'vuex';

// import 'es6-promise/auto';
import authentication from './modules/authentication';
import layout from './modules/layout';
import menu from './modules/menu';
import modulesMat from './modules/modulesMat';
import candidates from './modules/candidates';
import users from './modules/users';
import subjects from './modules/subjects';
import questions from './modules/questions';
import front from './modules/front';
import dashboard from './modules/dashboard';
import templates from './modules/templates';

export default createStore({
  state: {
    langIcon: '',
    langLangauge: '',
    isActive: false,
    isLoadingPage: false,
  },
  getters: {
    langIcon: (state) => state.langIcon,
    langLangauge: (state) => state.langLangauge,
    isLoadingPage: (state) => state.isLoadingPage,
  },
  mutations: {
    changeLang(state, payload) {
      localStorage.setItem('currentLanguage', payload.id);
      localStorage.setItem('currentLanguageIcon', payload.icon);
      state.langIcon = payload.icon || 'flag-icon-us';
      state.langLangauge = payload.id || 'EN';
      // window.location.reload();
    },
    change(state) {
      state.isActive = !state.isActive;
    },
    setLoadingPage(state, payload) {
      state.isLoadingPage = payload;
    },
  },
  actions: {
    setLang({ commit }, payload) {
      commit('changeLang', payload);
    },
  },
  modules: {
    authentication,
    layout,
    subjects,
    menu,
    candidates,
    users,
    modulesMat,
    questions,
    front,
    dashboard,
    templates,
  },
});
