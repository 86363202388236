<template>
  <div class="col-md-12 project-list">
    <div class="card">
      <div class="row">
        <div class="col-md-5 d-flex">
          <ul class="nav nav-tabs border-tab" id="top-tab" role="tablist" v-for="(item, index) in tab" :key="index">
            <li class="nav-item">
              <a
                class="nav-link"
                :class="{ active: item.active }"
                id="top-home-tab"
                data-bs-toggle="tab"
                href="javascript:void(0)"
                role="tab"
                aria-controls="top-home"
                :aria-selected="item.active ? 'true' : 'false'"
                @click.prevent="active(item)"
                ><vue-feather :type="item.icon"></vue-feather>{{ item.name }}</a
              >
            </li>
          </ul>
        </div>
        <div class="form-group col-md-3">
          <select class="form-select" v-model="selectedModule" @change="changeModule()">
            <option value="">Modules Filter (none)</option>
            <option v-for="(item, index) in allModules" :key="index" :value="item">
              {{ item.name }}
            </option>
          </select>
          <div>
            <br />
            <label for="select_all" class="d-block mb-0 text-danger">
              <input
                id="select_all"
                class="checkbox_animated"
                type="checkbox"
                value="true"
                v-model="checkedAllCandidate"
                @change="checkAll($event)"
              />Select All Candidates ({{ allCandidate.length }})
            </label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="d-flex justify-content-end">
            <a
              class="btn btn-primary px-2 me-2"
              href="javascript:void(0)"
              data-bs-toggle="modal"
              data-bs-target="#addCandidate"
              ><vue-feather class="me-1" type="plus-square"> </vue-feather>Add Candidate</a
            >
            <AddCandidate />
            <a class="btn btn-secondary px-2" href="javascript:void(0)" @click="exportData()"
              ><vue-feather class="me-1" type="download"> </vue-feather>Export</a
            >
          </div>
          <div class="d-flex justify-content-end">
            <button
              :disabled="selectedCandidates.length <= 0"
              class="btn btn-danger mt-2 px-2 b-block"
              href="javascript:void(0)"
              data-bs-toggle="modal"
              data-bs-target=".multiSendInvitation_test"
            >
              <vue-feather class="me-1" type="send"> </vue-feather>Assign test to selected candidates
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-if="selectedCandidates.length" class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <h5>
          Selected Candidates : <strong> {{ selectedCandidates.length }} </strong>
        </h5>
        <span v-for="(item, index) in allCandidate" :key="index">
          <span v-if="item.isActive"> {{ item.first_name + ' ' + item.last_name }} /</span>
        </span>
      </div>
    </div>
  </div>
  <div class="col-sm-12">
    <div class="card">
      <div class="card-body">
        <div v-if="!isLoadingCandidates" class="tab-content" id="top-tabContent">
          <div
            class="tab-pane fade"
            :class="{ 'active show': item.active }"
            :id="item.id"
            role="tabpanel"
            :aria-labelledby="item.label"
            v-for="(item, index) in tab"
            :key="index"
          >
            <component :is="item.type"></component>
          </div>
        </div>
        <div v-else>
          <div class="loader-box">
            <div class="loader-19"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import AddCandidate from '../addCandidate.vue';
import allPage from './allPage.vue';
import pendingPage from './pendingPage.vue';
import passedPage from './passedPage.vue';
import moment from 'moment';
export default {
  name: 'topMenu',
  components: {
    AddCandidate,
    allPage,
    pendingPage,
    passedPage,
  },
  data() {
    return {
      selectedModule: '',
      params: {},
      checkedAllCandidate: false,
      tab: [
        {
          type: 'allPage',
          name: 'All',
          active: true,
          icon: 'target',
          id: 'top-home',
          id1: 'top-tabContent',
          label: 'top-home-tab',
        },
        {
          type: 'pendingPage',
          name: 'Pending',
          active: false,
          icon: 'info',
          id: 'top-profile',
          label: 'profile-top-tab',
        },
        {
          type: 'passedPage',
          name: 'Passed',
          active: false,
          icon: 'check-circle',
          id: 'top-contact',
          label: 'contact-top-tab',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['allModules', 'isLoadModules', 'allCandidate', 'selectedCandidates', 'isLoadingCandidates']),
  },
  methods: {
    ...mapActions(['getAllCandidates', 'exportCandidates']),
    checkAll() {
      this.$store.commit('selectAllCandidate', this.checkedAllCandidate);
    },
    changeModule() {
      this.params.module_id = this.selectedModule.id;
      this.checkedAllCandidate = false;
      //this.getAllCandidates(this.params);
      this.$store.commit('selectAllCandidate', this.checkedAllCandidate);
    },
    exportData() {
      this.params.export = true;
      this.exportCandidates(this.params).then((response) => {
        if (!response) {
          this.$toast.show('Select Module filter !', {
            theme: 'outline',
            type: 'error',
          });
        } else {
          this.params.export = '';
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));
          const fileName = `Tests-${moment().format('DD-MM-YYYY')}.xls`;
          const fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', fileName);
          document.body.appendChild(fileLink);
          fileLink.click();
        }
      });
    },
    active(item) {
      if (!item.active) {
        this.tab.forEach((a) => {
          a.active = false;
        });
      }
      item.active = !item.active;
    },
  },
};
</script>
