<template>
  <div class="wrapper">
    <!-- Top content -->
    <div class="container">
      <div class="row">
        <div class="col-md-12">
          <div class="multisteps_form overflow-hidden position-relative">
            <div class="questions col-md-8 mx-auto text-center">
              <div class="multisteps_form_panel d-flex justify-content-center align-items-center">
                <div v-if="!isLoading" class="pt-3 px-4">
                  <div v-if="questions[0]?.teacher_logo" class="logo_area mb-3">
                    <img :src="baseURL + questions[0]?.teacher_logo" alt="Logo" />
                  </div>
                  <h4 class="mb-3">Bonjour, vous avez été invité(e) à passer un test.</h4>
                  <div class="mb-5">
                    <p>Ce test est composé de :</p>
                    <div v-for="(question, index) in questions" :key="index">
                      <p class="mb-0">
                        <strong>
                          - Module {{ question.module }}: {{ question.count_questions }} questions ({{
                            secondsToMinutes(question.timer)
                          }}
                          minutes)</strong
                        >
                      </p>
                    </div>

                    <p>
                      Merci de prévoir <strong class="text-danger"> {{ this.minutes }} minutes consécutives </strong>
                      <br />
                      <span class="text-danger">(au-delà de ce temps, le test sera automatiquement fermé).</span><br />
                      Vous pourrez répondre aux questions dans l'ordre de votre choix .<br />Vous aurez également la
                      possibilité de modifier vos réponses (jusqu'à ce que le temps alloué soit écouté)
                    </p>
                  </div>
                  <button
                    v-if="canStart"
                    type="button"
                    class="f_btn next_btn text-uppercase"
                    id="nextBtn"
                    @click="startTest"
                  >
                    Démarrer le test
                  </button>
                  <div v-else>
                    <p>
                      Le test sera disponible à partir de <span class="text-warning">{{ openingDate }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { baseURL } from '@/constants/config';

export default {
  data() {
    return {
      baseURL: baseURL,
      idQuest: '',
      questions: {},
      minutes: 0,
      isLoading: false,
      totalSeconds: 0,
      canStart: false,
      openingDate: '',
    };
  },
  computed: {
    ...mapGetters(['isLoadTestFront']),
  },
  methods: {
    ...mapActions(['getQuestionTemplate']),
    secondsToMinutes(seconds) {
      return Math.floor(seconds / 60);
    },
    getQuestionsData() {
      this.getQuestionTemplate(this.idQuest).then((res) => {
        this.isLoading = false;
        if (res) {
          this.canStart = res.can_start;
          this.openingDate = res.opening_date;
          this.questions = res.data;
          this.questions.map((question) => {
            if (question.timer) {
              this.minutes += Math.floor(question.timer / 60);
              this.totalSeconds += question.timer;
            }
          });
        }
      });
    },
    startTest() {
      this.$router.push({
        name: 'loadTemplate',
        params: { idTemplate: this.questions[0].template?.id, token: this.idQuest },
      });
    },
  },
  created() {
    this.idQuest = this.$route.params.token;
    this.isLoading = true;
    if (this.idQuest) {
      this.getQuestionsData();
    }
  },
};
</script>
<style scoped>
.logo_area img {
  max-height: 150px;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  background-image: url('../../assets/images/front/bg_0.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

.multisteps_form {
  max-width: 88.125rem;
  min-height: 30.4375rem;
  padding: 1rem;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: #ffffff;
  background-size: 88.125rem 16.5rem;
  margin: 2rem auto;
  -webkit-box-shadow: 0 -3em 3em rgba(73, 73, 73, 0.1), 0.3em 0.3em 1em rgba(73, 73, 73, 0.1);
  box-shadow: 0 -3em 3em rgba(73, 73, 73, 0.1), 0.3em 0.3em 1em rgba(73, 73, 73, 0.1);
}

p {
  font-size: 1.3rem;
}

.f_btn {
  font-size: 1.125rem;
  font-weight: 800;
  padding: 0.9375rem 1.5625rem;
  outline: none;
  border: none;
  background: -webkit-gradient(linear, left top, right top, from(#18eaf9), to(#2894b7));
  background: linear-gradient(to right, #18eaf9, #2894b7);
}
.f_btn:hover {
  color: #ffffff;
  background: -webkit-gradient(linear, left top, right top, from(#2894b7), to(#18eaf9));
  background: linear-gradient(to right, #18eaf9, #2894b7);
  -webkit-transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

@media (min-width: 1500px) {
  .container {
    max-width: 1510px;
  }
}
/* ==================================================
*	01 - media screen and (max-width: 991px)
*	02 - media screen and (max-width: 767px)
*	03 - media screen and (max-width: 575px)
*	04 - media screen and (max-width: 380px)
*	05 - media screen and (max-width: 320px)
*   XX-Large devices (larger desktops)
*   No media query since the xxl breakpoint has no upper bound on its width
================================================== */
@media screen and (max-width: 991.98px) {
  .logo_area {
    padding-left: 0rem !important;
  }
  .multisteps_form {
    max-width: 86rem;
    min-height: 43rem;
  }

  .f_btn {
    font-size: 1rem;
    font-weight: 700;
    padding: 0.7375rem 1.2625rem;
  }
}
@media screen and (max-width: 767.98px) {
  .logo_area {
    text-align: center;
    display: block;
  }

  .multisteps_form {
    max-width: 83rem;
    min-height: 40rem;
  }
}
@media screen and (max-width: 575.98px) {
  .logo_area {
    text-align: center;
    padding-left: 0rem !important;
  }

  .multisteps_form {
    max-width: 81rem;
    min-height: 38rem;
  }
}
@media screen and (max-width: 379.98px) {
  .multisteps_form {
    max-width: 79rem;
    min-height: 33rem;
  }
}
@media screen and (max-width: 319.98px) {
  .multisteps_form {
    min-height: 32rem;
  }
}
</style>
