<template>
  <div class="card animate-chk">
    <div class="card-header py-3">
      <h5>Add answers and check the correct answer</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <label v-for="(item, index) in answers" :key="index" class="d-block" :for="`chk-ani-${index}`">
            <input class="checkbox_animated" :id="`chk-ani-${index}`" type="checkbox" /> {{ item.title }}
          </label>
          <div class="new-task-wrapper input-group">
            <input
              type="text"
              class="form-control"
              id="new-answer"
              placeholder="Enter new answer here. . ."
              v-on:keyup.enter="addnewanswer"
              v-model="task"
            /><span
              class="btn btn-info btn-md d-flex input-group-text add-new-task-btn"
              id="add-task"
              @click="addnewanswer()"
              ><vue-feather type="plus-circle"></vue-feather> &nbsp; Add Answer</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      task: '',
      answers: [],
    };
  },
  methods: {
    addnewanswer() {
      if (this.task !== '') {
        let idAns = this.answers.length + 1;
        this.answers.push({ id: idAns, title: this.task });
        this.task = '';
        this.$toast.show('Answer added.', {
          theme: 'outline',
          position: 'top-right',
          type: 'success',
          duration: 2000,
        });
      }
    },
  },
};
</script>
