<template>
  <div class="col-xl-3 box-col-6">
    <div class="md-sidebar">
      <a class="btn btn-primary md-sidebar-toggle" @click="collapse()">Levels filter</a>
      <div class="md-sidebar-aside job-left-aside custom-scrollbar" :class="filtered ? 'open' : ''">
        <div class="email-left-aside">
          <div class="card">
            <div class="card-body">
              <div class="email-app-sidebar left-bookmark task-sidebar">
                <ul v-if="levels" class="nav main-menu" role="tablist">
                  <!-- <li class="nav-item">
                    <router-link
                      class="badge-light-primary btn-block btn-mail w-100"
                      :to="`${this.$route.params.type}/add`"
                      ><vue-feather class="me-2" type="check-circle"></vue-feather>New Level</router-link
                    >
                  </li> -->
                  <li class="nav-item">
                    <span class="main-title d-flex"
                      >Tests list
                      <a
                        class="btn btn-xs btn-info px-2 ms-auto"
                        href="javascript:void(0)"
                        data-bs-toggle="modal"
                        data-bs-target="#createModuleLevel"
                        @click="selectLevel(null)"
                        ><vue-feather class="me-0" type="plus"></vue-feather></a
                    ></span>
                    <add-level-modal :module="selectedModule" />
                  </li>
                  <li v-for="(item, index) in levels" :key="index">
                    <a
                      :class="this.activeTab == `pills-quest-${index}` ? 'active badge-light-primary' : ''"
                      :id="`pills-quest-${index}`"
                      data-bs-toggle="pill"
                      :href="`#quest-${index}`"
                      role="tab"
                      :aria-controls="`quest-${index}`"
                      aria-selected="true"
                      @click="selectLevel(item)"
                    >
                      <span class="title d-inline-flex align-items-center" @click="active(`pills-quest-${index}`)"
                        >{{ item.name }}<span>&nbsp; (Score {{ item.score }})</span>
                      </span>
                      <span class="d-inline-flex justify-content-end">
                        <a
                          class="p-0"
                          href="javascript:void(0)"
                          data-bs-toggle="modal"
                          data-bs-target="#createModuleLevel"
                          @click="selectLevel(item)"
                          ><vue-feather type="edit"></vue-feather
                        ></a>
                        <a
                          class="p-0"
                          href="javascript:void(0)"
                          data-bs-toggle="modal"
                          data-bs-target=".modal-delete"
                          @click="selectLevel(item)"
                          ><vue-feather class="text-danger m-0" type="trash"></vue-feather
                        ></a>
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xl-9 col-md-12 box-col-12">
    <div class="email-right-aside bookmark-tabcontent">
      <div class="card email-body radius-left">
        <div class="ps-0">
          <div class="tab-content">
            <div v-for="(item, index) in levels" :key="index">
              <pillsQuest
                v-if="item.questions.length"
                :index="index"
                :moduleName="selectedModule.name"
                :questions="item.questions"
              />
              <div v-else>
                <div
                  class="tab-pane fade"
                  :class="this.activeTab == `pills-quest-${index}` ? 'active show' : 'd-none'"
                  :id="`quest-${index}`"
                  role="tabpanel"
                  :aria-labelledby="`quest-${index}`"
                >
                  <div class="card mb-0">
                    <div class="card-header d-flex py-3">
                      <h5 class="me-4">No questions available for this level.</h5>
                    </div>
                    <div class="card-body">
                      <div class="taskadd">
                        <router-link
                          class="btn btn-primary px-3"
                          href="javascript:void(0)"
                          :to="`${this.$route.params.type}/add/${selectedModule.id}/${selectedLevel.id}`"
                        >
                          <vue-feather class="me-2" type="plus" size="16"></vue-feather>Add Question
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade modal-delete"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <validation-modal
        :title="'Delete'"
        :message="'Voulez vous vraiment supprimer cet élément ?'"
        @validationModal="deleteLevelValidation"
      />
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import pillsQuest from './pillsQuest.vue';
import AddLevelModal from './addLevelModal.vue';
import validationModal from '@/components/validationModal.vue';

export default {
  components: {
    pillsQuest,
    AddLevelModal,
    validationModal,
  },
  computed: {
    ...mapGetters({
      activeTab: 'activeclass',
      selectedModule: 'selectedModule',
      selectedLevel: 'selectedLevel',
    }),
    levels() {
      if (this.selectedModule.levels?.length) {
        this.selectedLvl = this.selectedModule.levels[0];
      }
      return this.selectedModule.levels;
    },
  },
  data() {
    return {
      filtered: false,
      selectedLvl: '',
    };
  },
  methods: {
    ...mapActions(['deleteLevel']),
    selectLevel(level) {
      if (!level) {
        level = { name: '', score: '' };
      }
      this.selectedLvl = level;
      this.$store.commit('setSelectedLevel', level);
    },
    deleteLevelValidation(validation) {
      if (validation) {
        let params = {
          keyModule: this.selectedModule.key,
          idLvl: this.selectedLevel.id,
        };
        this.deleteLevel(params).then((res) => {
          if (res && (res.status === 200 || res.status === 201)) {
            this.$toast.show('Delete Success', {
              theme: 'outline',
              type: 'warning',
            });
          } else {
            this.$toast.show('Error deleting Level !', {
              theme: 'outline',
              type: 'error',
            });
          }
        });
      }
    },
    active: function (message) {
      this.$store.dispatch('active', message);
    },
    collapse() {
      this.filtered = !this.filtered;
    },
  },
};
</script>
