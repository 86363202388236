export const puechaseSalesDetails1 = [
  {
    widgetClass: 'widget-round secondary',
    svgIcon1: 'stroke-file',
    svgIcon2: 'halfcircle',
    number: '10,000',
    title: 'Questions',
    growthClass: 'font-secondary f-w-500',
    iconClass: 'icon-arrow-up icon-rotate me-1',
    growthNumber: '+50%',
  },
  {
    widgetClass: 'widget-round primary',
    svgIcon1: 'fill-file',
    svgIcon2: 'halfcircle',
    number: '4,200',
    title: 'Tests',
    growthClass: 'font-primary f-w-500',
    iconClass: 'icon-arrow-up icon-rotate me-1',
    growthNumber: '+70%',
  },
];
export const puechaseSalesDetails2 = [
  {
    widgetClass: 'widget-round success',
    svgIcon1: 'rate',
    svgIcon2: 'halfcircle',
    number: '5700',
    title: 'Passed Test',
    growthClass: 'font-success f-w-500',
    iconClass: 'icon-arrow-up icon-rotate me-1',
    growthNumber: '+70%',
  },
  {
    widgetClass: 'widget-round warning',
    svgIcon1: 'fill-calender',
    svgIcon2: 'halfcircle',
    number: '5',
    title: 'Pending Tests',
    growthClass: 'font-success f-w-500',
    iconClass: 'icon-arrow-up icon-rotate me-1',
    growthNumber: '+70%',
  },
];

export const puechaseSalesDetails3 = [
  {
    widgetClass: 'widget-round info',
    svgIcon1: 'fill-board',
    svgIcon2: 'halfcircle',
    number: '4',
    title: 'Modules',
    growthClass: 'font-warning f-w-500',
    iconClass: 'icon-arrow-down icon-rotate me-1',
    growthNumber: '-20%',
  },
  {
    widgetClass: 'widget-round warning',
    svgIcon1: 'user-visitor',
    svgIcon2: 'halfcircle',
    number: '7000',
    title: 'Candidats',
    growthClass: 'font-warning f-w-500',
    iconClass: 'icon-arrow-down icon-rotate me-1',
    growthNumber: '-20%',
  },
];
