import { subjectsService } from '../../_services';

const state = {
  isLoadSubjects: false,
  listSubjects: [],
};
// getters
const getters = {
  allSubjects: (state) => state.listSubjects,
  isLoadSubjects: (state) => state.isLoadSubjects,
};
const actions = {
  active: (context, payload) => {
    context.commit('active', payload);
  },
  async getAllSubjects({ commit }, payload) {
    await subjectsService.getAllSubjects().then(
      (subjects) => commit('getAllSubjectSuccess', subjects.data.data),
      (error) => commit('getAllSubjectsFailure', error)
    );
  },
  addSubject({ commit }, payload) {
    return subjectsService.addNewSubject(payload).then(
      (subject) => {
        if (subject.status === 200) {
          commit('setSubjectSuccess', subject.data);
        }
        return subject.data;
      },
      (error) => error
    );
  },
  updateSubject({ commit }, payload) {
    return subjectsService.updateSubject(payload.id, payload).then(
      (module) => module,
      (error) => error
    );
  },
  async deleteSubject({ commit }, payload) {
    return await subjectsService.deleteSubject(payload).then(
      (question) => {
        commit('removeSubjectFromList', payload);
        return question.data;
      },
      (error) => commit('getQuestionFailure', error)
    );
  },
};
const mutations = {
  active: (state, payload) => {
    state.activeclass = payload;
  },
  getAllSubjectSuccess(state, subjects) {
    state.listSubjects = subjects;
  },
  getAllSubjectsFailure(state, error) {
    state.errors = { error };
  },
  setSubjectSuccess(state, subject) {
    state.listSubjects.push(subject);
  },
  setLoadingSubjects(state, load) {
    state.isLoadSubjects = load;
  },
  removeSubjectFromList(state, idSubject) {
    const objWithIdIndex = state.listSubjects.findIndex((obj) => obj.id === idSubject);
    if (objWithIdIndex > -1) {
      state.listSubjects.splice(objWithIdIndex, 1);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
