import { APIFRONT } from '../modules/axios';

const END_POINT_QUESTIONS = 'questions';

const getQuestionsFront = (idQuestions) => APIFRONT.get(`${END_POINT_QUESTIONS}/${idQuestions}`);
const geResultsFront = (idQuestions) => APIFRONT.get(`${END_POINT_QUESTIONS}/test/result/${idQuestions}`);
const getQuestionTemplate = (idQuestions) => APIFRONT.get(`${END_POINT_QUESTIONS}/first/screen/${idQuestions}`);
const sendQuestionsTest = (idQuestions, params) => APIFRONT.post(`${END_POINT_QUESTIONS}/${idQuestions}`, params);
const updateTokenTest = (tokenTest) => APIFRONT.put(`${END_POINT_QUESTIONS}/${tokenTest}`);
const startTest = (tokenTest) => APIFRONT.post(`${END_POINT_QUESTIONS}/start/test/${tokenTest}`);

export const frontService = {
  getQuestionsFront,
  sendQuestionsTest,
  getQuestionTemplate,
  updateTokenTest,
  startTest,
  geResultsFront,
};
