<template>
  <Breadcrumbs title="User Cards" main="Users" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-md-2 ms-auto d-flex mb-3">
        <a
          class="btn btn-info ms-auto f-w-600 d-flex align-items-center"
          href="javascript:void(0)"
          data-bs-toggle="modal"
          data-bs-target="#addUserModal"
          ><vue-feather class="me-2" type="plus"></vue-feather>Add User</a
        >
      </div>
    </div>

    <AddUserModal />
    <div class="row" v-if="!isLoadUsers">
      <card1 :card="allUsers" @selectUser="getIdUser" />
    </div>
    <div
      class="modal fade modal-deleteUser"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <validation-modal
        :title="'Delete'"
        :message="'Voulez vous vraiment supprimer cet élément ?'"
        @validationModal="deleteUserValidation"
      />
    </div>
    <div
      class="modal fade modal-blockUser"
      tabindex="-1"
      role="dialog"
      aria-labelledby="myLargeModalLabel"
      aria-hidden="true"
    >
      <validation-modal
        :title="'Update Status User'"
        :message="'Voulez vous vraiment mettre à jour l\'utilisateur ?'"
        @validationModal="enableDisableUser"
      />
    </div>
  </div>
</template>

<script>
import card1 from './card1.vue';
import { mapActions, mapGetters } from 'vuex';
import validationModal from '@/components/validationModal.vue';
import AddUserModal from './addUserModal.vue';

export default {
  name: 'userCards',
  components: {
    card1,
    validationModal,
    AddUserModal,
  },
  data() {
    return {
      selectedUser: '',
    };
  },
  methods: {
    ...mapActions(['getAllUsers', 'deleteUser', 'updateUser']),
    getIdUser(userData) {
      this.selectedUser = userData;
    },
    deleteUserValidation(validation) {
      if (validation) {
        this.deleteUser(this.selectedUser.id).then(() => {
          this.$toast.show('Delete Success', {
            theme: 'outline',
            type: 'warning',
          });
        });
      }
      this.selectedUser = '';
    },
    enableDisableUser(validation) {
      if (validation) {
        this.selectedUser.status = this.selectedUser.status === 1 ? 2 : 1;
        this.updateUser(this.selectedUser).then(() => {
          this.$toast.show('Update Success', {
            theme: 'outline',
            type: 'success',
          });
        });
      }
      this.selectedUser = '';
    },
  },
  created() {
    this.getAllUsers();
  },
  computed: {
    ...mapGetters({
      isLoadUsers: 'isLoadUsers',
      allUsers: 'allUsers',
    }),
  },
};
</script>
