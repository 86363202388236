import { frontService } from '../../_services';
import router from '../../router/index';

const state = {
  isLoadTestFront: false,
  testTimer: 0,
  testsList: [],
  selectedTest: {},
};
// getters
const getters = {
  isLoadTestFront: (state) => state.isLoadTestFront,
  testTimer: (state) => state.testTimer,
  testsList: (state) => state.testsList,
  selectedTest: (state) => state.selectedTest,
};
const actions = {
  async getQuestionTemplate({ commit }, payload) {
    commit('setLoadingTestFront', true);
    return await frontService.getQuestionTemplate(payload).then(
      (question) => {
        commit('setLoadingTestFront', false);
        return question.data;
      },
      (error) => {
        console.error(error);
        router.push({ name: 'expiredToken', params: { token: payload } });
      }
    );
  },
  async getQuestionsForFront({ commit }, payload) {
    commit('setLoadingTestFront', true);
    return await frontService.getQuestionsFront(payload).then(
      (question) => {
        commit('successLoadTestFront', question.data.data);
        commit('setLoadingTestFront', false);
        return question.data;
      },
      (error) => {
        router.push({ name: 'expiredToken', params: { token: payload } });
        console.error(error);
      }
    );
  },
  async getQuestionsResult({ commit }, { idTest, params }) {
    return await frontService.sendQuestionsTest(idTest, params).then(
      (result) => {
        return result.data;
      },
      (error) => {
        return error;
      }
    );
  },
  async startTestForFront({ commit }, payload) {
    return await frontService.startTest(payload).then(
      (question) => {
        return question.data;
      },
      (error) => {
        console.error(error);
      }
    );
  },
  async updateTokenTestForFront({ commit }, payload) {
    return await frontService.updateTokenTest(payload).then(
      (question) => {
        return question.data;
      },
      (error) => {
        console.error(error);
      }
    );
  },
  geResultsForFront({ commit }, payload) {
    return frontService.geResultsFront(payload).then(
      (question) => {
        return question.data;
      },
      (error) => {
        console.error(error);
      }
    );
  },
};
const mutations = {
  successLoadTestFront(state, tests) {
    state.testsList = tests;
  },
  setSelectedTest(state, index) {
    state.selectedTest = state.testsList[index];
  },
  setLoadingTestFront(state, load) {
    state.isLoadTestFront = load;
  },
  setTimer(state, time) {
    state.testTimer = time;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
