<template>
  <Breadcrumbs main="Candidates" title="Test Result" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header py-3">
            <h5>
              <strong>{{ testData.first_name }} {{ testData.last_name }} </strong> :
            </h5>
            <h5>
              Total score :
              <span class="text-danger">{{ testData.candidate_score }} / {{ testData.total_score }}</span> -
              {{ testData.level }}
            </h5>
          </div>
          <div class="card-body">
            <ul class="nav nav-pills nav-primary mb-3" id="pills-tab" role="tablist">
              <li
                class="nav-item"
                v-for="(test, indexTest) in testData.modules"
                :key="indexTest"
                @click="activeTab = indexTest"
              >
                <a
                  :class="{ active: activeTab == indexTest }"
                  class="nav-link"
                  :id="`pills-${indexTest}-tab`"
                  data-bs-toggle="pill"
                  :href="`#pills-${indexTest}`"
                  role="tab"
                  :aria-controls="`pills-${indexTest}`"
                  aria-selected="true"
                  >{{ test.module }} : {{ test.score }} <span v-if="test.module_score">/ {{ test.module_score }} </span>
                  <div class="d-flex"></div
                ></a>
              </li>
            </ul>
            <div class="tab-content" id="pills-tabContent">
              <div
                v-for="(test, indexTest) in testData.modules"
                :key="indexTest"
                :class="{ 'show active': activeTab == indexTest }"
                class="tab-pane fade"
                :id="`pills-${indexTest}`"
                role="tabpanel"
                :aria-labelledby="`pills-${indexTest}-tab`"
              >
                <div class="table-responsive product-table">
                  <table class="display table" id="basic-1">
                    <thead>
                      <tr>
                        <th width="30%">Questions</th>
                        <th width=" 50%">Answers</th>
                        <th class="text-center" width="10%">Score</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="question in test.candidate_answers" :key="question">
                        <td>
                          <strong>{{ question.title }} </strong>
                        </td>
                        <td>
                          <ul>
                            <li v-for="(item, index) in question.answers" :key="index">
                              <span v-if="question.correct_answers.includes(item.id)" class="text-success">
                                <font-awesome-icon :icon="['fas', 'check']" /> {{ item.answer }}
                              </span>
                              <span v-else class="text-danger">
                                <font-awesome-icon :icon="['fas', 'minus']" /> {{ item.answer }}
                              </span>
                              &nbsp;
                              <span
                                :class="question.correct_answers.includes(item.id) ? 'text-success' : 'text-danger'"
                                v-if="question.checked_answers.includes(item.id)"
                                ><strong>(Selected)</strong></span
                              >
                            </li>
                          </ul>
                        </td>
                        <td class="text-center">
                          <strong>{{ question.answer_score }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      testData: {},
      activeTab: 0,
    };
  },
  computed: {
    ...mapGetters(['allModules', 'allSubjects']),
  },
  methods: {
    ...mapActions(['getResultTestCandidate']),
  },
  created() {
    const idTest = this.$route.params.idTest;
    this.getResultTestCandidate(idTest).then((res) => {
      this.testData = res;
    });
  },
};
</script>
