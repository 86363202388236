import axios from 'axios';
import { API_URL, FRONT_API_URL } from '../constants/config';

const API = axios.create({
  baseURL: API_URL,
  defaults: {
    headers: {
      common: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      'Content-type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Accept-Encoding': 'gzip, deflate',
    },

    withCredentials: true,
  },
});

const API_FILE_EXPORT = axios.create({
  baseURL: API_URL,
  responseType: 'arraybuffer',
  defaults: {
    headers: {
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      common: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Accept-Encoding': 'gzip, deflate',
    },
    withCredentials: true,
  },
});

const APIFRONT = axios.create({
  baseURL: FRONT_API_URL,
  defaults: {
    headers: {
      common: {
        'X-Requested-With': 'XMLHttpRequest',
      },
      'Content-type': 'application/json',
      'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, PATCH, DELETE',
      'Accept-Encoding': 'gzip, deflate',
    },
    withCredentials: true,
  },
});

API.interceptors.request.use(
  (config) => {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.access_token) {
      config.headers['Authorization'] = `Bearer ${user.access_token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

API_FILE_EXPORT.interceptors.request.use(
  (config) => {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.access_token) {
      config.headers['Authorization'] = `Bearer ${user.access_token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

API.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      //this.$swal('Session Expiré!');
      window.location = '/authentication/login/validate';
      localStorage.clear();
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

APIFRONT.interceptors.request.use(
  (config) => {
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.access_token) {
      config.headers['Authorization'] = `Bearer ${user.access_token}`;
    }

    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

export { API, API_FILE_EXPORT, APIFRONT };
