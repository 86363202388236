import { modulesService } from '../../_services';

const state = {
  isLoadModules: false,
  listModules: [],
  selectedModule: {},
  selectedLevel: {},
};
// getters
const getters = {
  allModules: (state) => state.listModules,
  selectedModule: (state) => state.selectedModule,
  selectedLevel: (state) => state.selectedLevel,
  isLoadModules: (state) => state.isLoadModules,
};
const actions = {
  active: (context, payload) => {
    context.commit('active', payload);
  },
  async getAllModules({ commit }) {
    commit('setLoadingModule', true);
    await modulesService.getAllModules().then(
      (modules) => commit('getAllModulesSuccess', modules.data.data),
      (error) => console.error(error)
    );
  },
  addLevelToModule({ commit }, payload) {
    return modulesService.addLevelModule(payload).then(
      (response) => {
        if (response.status === 200 || response.status === 200) {
          commit('getModuleSuccess', response.data);
        }
        return response;
      },
      (error) => error
    );
  },
  updateLevel({ commit }, payload) {
    return modulesService.updateLevel(payload.id, payload).then(
      (module) => module,
      (error) => error
    );
  },
  async deleteLevel({ commit }, payload) {
    return await modulesService.deleteLevelFromModule(payload.keyModule, payload.idLvl).then(
      (module) => {
        commit('getModuleSuccess', module.data);
        return module;
      },
      (error) => commit('getQuestionFailure', error)
    );
  },
  async getModule({ commit }, payload) {
    await modulesService.getModule(payload).then(
      (module) => commit('getModuleSuccess', module.data),
      (error) => console.error(error)
    );
  },
  async deleteModule({ commit }, payload) {
    return await modulesService.deleteModule(payload).then(
      (module) => {
        commit('removeModuleFromList', payload);
        return module.data;
      },
      (error) => commit('getQuestionFailure', error)
    );
  },
  addModule({ commit }, payload) {
    return modulesService.addNewModule(payload).then(
      (module) => {
        if (module.status === 200) {
          commit('setModuleSuccess', module.data);
        }
        return module.data;
      },
      (error) => error
    );
  },
};
const mutations = {
  setModuleSuccess(state, module) {
    state.listModules.push(module);
    let oldMenu = JSON.parse(localStorage.getItem('menu'));
    let newModule = {
      id: module.id,
      path: '/tests/' + module.key,
      title: module.name,
      type: 'link',
      active: false,
    };
    oldMenu.data[2].children.push(newModule);
    localStorage.setItem('menu', JSON.stringify(oldMenu));
  },
  getAllModulesSuccess(state, modules) {
    state.listModules = modules;
    state.isLoadModules = false;
    let oldMenu = JSON.parse(localStorage.getItem('menu'));
    let newMenu = modules.map(
      (module) =>
        (module = {
          id: module.id,
          path: '/tests/' + module.key,
          title: module.name,
          type: 'link',
          active: false,
        })
    );
    oldMenu.data[2].children = newMenu;
    localStorage.setItem('menu', JSON.stringify(oldMenu));
    this.commit('menu/setMenu', oldMenu.data);
  },
  getModuleSuccess(state, module) {
    state.selectedModule = module;
  },
  setLoadingModule(state, load) {
    state.isLoadModules = load;
  },
  setSelectedLevel(state, level) {
    state.selectedLevel = level;
  },
  removeModuleFromList(state, idModule) {
    const objWithIdIndex = state.listModules.findIndex((obj) => obj.id === idModule);
    if (objWithIdIndex > -1) {
      state.listModules.splice(objWithIdIndex, 1);
    }
    let oldMenu = JSON.parse(localStorage.getItem('menu'));
    const objWithIdIndexMenu = oldMenu.data[2].children.findIndex((obj) => obj.id === idModule);
    if (objWithIdIndexMenu > -1) {
      oldMenu.data[2].children.splice(objWithIdIndexMenu, 1);
      localStorage.setItem('menu', JSON.stringify(oldMenu));
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
