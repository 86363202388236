<template>
  <div
    class="tab-pane fade"
    :class="this.activeTab == `pills-quest-${index}` ? 'active show' : 'd-none'"
    :id="`quest-${index}`"
    role="tabpanel"
    :aria-labelledby="`quest-${index}`"
    v-if="questions"
  >
    <div v-for="(question, index) in questions" :key="index" class="card mb-0">
      <div class="card-header d-flex py-3">
        <div>
          <h5 v-if="question?.title" class="mb-1">{{ question.title.substring(0, 100) }}</h5>
          <p v-if="question?.description">{{ question.description.substring(0, 200) }}</p>
        </div>
        <div class="action-block">
          <router-link
            class="f-w-600 mw-100 d-inline-flex justify-content-end"
            href="javascript:void(0)"
            :to="`${this.$route.params.type}/edit${question ? '/' + question?.id : ''}`"
            ><vue-feather class="me-2" type="edit"></vue-feather>Edit</router-link
          >
          <router-link
            class="f-w-600 mw-100 d-inline-flex justify-content-end text-success"
            href="javascript:void(0)"
            :to="{
              name: 'testCandidateShow',
              params: { idTemplate: 1, token: question?.id },
            }"
            ><vue-feather class="me-2" type="eye"></vue-feather>Show</router-link
          >
          <a
            class="f-w-600 mw-100 d-inline-flex justify-content-end text-danger"
            href="javascript:void(0)"
            @click="basic_warning_alert(question.id)"
          >
            <vue-feather class="me-2" type="trash" size="16"></vue-feather>Delete
          </a>
        </div>
      </div>
      <div class="card-body p-0">
        <div class="taskadd">
          <div class="table-responsive">
            <table v-if="question?.sub_questions" class="table">
              <tr v-for="(item, index) in question.sub_questions" :key="index" class="border-bottom-primary">
                <td>
                  <h6 class="task_title_0 p-0">{{ item.order }} - {{ item.title }}</h6>
                  <p class="project_name_0 p-0">Score : {{ item.question_score }}</p>
                  <p v-if="item.question_type?.name" class="project_name_0 p-0">Type : {{ item.question_type.name }}</p>
                </td>
                <td>
                  <p class="task_desc_0"></p>
                </td>
                <td></td>
                <td>
                  <a class="p-0 text-danger" href="javascript:void(0)" @click="basic_warning_alert(item.id)">
                    <vue-feather class="text-danger" type="trash-2" size="16"></vue-feather>
                  </a>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  props: ['index', 'questions', 'moduleName'],
  data() {
    return {
      typeTwoCol: ['Reading', 'Writing'],
    };
  },
  computed: {
    ...mapGetters({
      activeTab: 'activeclass',
    }),
  },
  methods: {
    ...mapActions(['deleteQuestion']),
    basic_warning_alert: function (item) {
      this.$swal({
        icon: 'warning',
        title: 'Are you sure?',
        text: 'Once deleted, you will not be able to recover this question!',
        showCancelButton: true,
        confirmButtonText: 'Ok',
        confirmButtonColor: '#e64942',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#efefef',
      }).then((result) => {
        if (result.value) {
          this.deleteQuestion(item).then((res) => {
            this.$swal({
              icon: 'success',
              text: 'Poof! Your question has been deleted!',
              type: 'success',
            });
            const parentIdIndex = this.questions.findIndex((obj) => obj.id === item);
            if (parentIdIndex > -1) {
              this.questions.splice(parentIdIndex, 1);
            } else {
              this.questions.map((question) => {
                let childIdIndex = question.sub_questions.findIndex((obj) => obj.id === item);
                if (childIdIndex > -1) {
                  question.sub_questions.splice(childIdIndex, 1);
                }
              });
            }
            this.$toast.show('Delete Success', {
              theme: 'outline',
              type: 'success',
            });
          });
        } else {
          this.$swal({
            text: 'Your question is safe!',
          });
        }
      });
    },
  },
};
</script>
<style scoped>
.border-bottom-primary {
  border-bottom: 1px solid #7366ff69;
}
.mw-100 {
  min-width: 100px;
}
</style>
