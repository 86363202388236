import { createRouter, createWebHistory } from 'vue-router';
import AuthGuard from '../utils/auth.guard';

import LoginValidate from '../pages/authentication/login';
import Body from '../components/body';
import Default from '../pages/dashboard/defaultPage.vue';
import Questions from '../pages/questions/indexPage.vue';
import Candidates from '../pages/candidates/indexPage.vue';
import createQuest from '../pages/questions/createquestion/createQuest.vue';
import editQuest from '../pages/questions/createquestion/editQuest.vue';
// user
import userProfile from '../pages/users/profile/userProfile.vue';
import userCards from '../pages/users/list/userCards.vue';
import userEdit from '../pages/users/edit/userEdit.vue';
import subjectsList from '../pages/subjects/indexPage.vue';
import modulesList from '../pages/subjectsModules/indexPage.vue';
import TestResult from '../pages/candidates/candidateTestResult.vue';
// Front
import StartTest from '../pages/front/indexPage.vue';
import loadTemplate from '../pages/front/loadTemplate.vue';
import expiredToken from '../pages/front/templates/templateExpiredToken.vue';

const routes = [
  {
    path: '/authentication/login/validate',
    name: 'LoginValidate',
    component: LoginValidate,
    meta: {
      title: 'Connexion | TrustBooking',
    },
  },
  {
    path: '/',
    component: Body,
    meta: {
      loginRequired: true,
    },
    children: [
      {
        path: '',
        name: 'defaultRoot',
        component: Default,
      },
      {
        path: 'tests',
        name: 'tests',
        meta: {
          title: 'Task | Cuba - Premium Admin Template',
        },
        children: [
          {
            path: ':type',
            name: 'testsList',
            component: Questions,
          },
          {
            path: ':type/add/:module/:lvl',
            name: 'addQuest',
            component: createQuest,
          },
          {
            path: ':type/edit/:id?',
            name: 'editQuest',
            component: editQuest,
          },
        ],
      },
      {
        path: 'candidates',
        name: 'Candidates',
        children: [
          {
            path: 'list',
            name: 'CandidatesList',
            component: Candidates,
          },
          {
            path: 'test-result/:idTest?',
            name: 'ResultTest',
            component: TestResult,
          },
        ],
      },
      {
        path: '/users',
        name: 'usres',
        children: [
          {
            path: 'profile',
            name: 'profile',
            component: userProfile,
            meta: {
              title: 'Users Profile | Cuba - Premium Admin Template',
            },
          },
          {
            path: 'edit',
            name: 'edit',
            component: userEdit,
            meta: {
              title: 'Users Edit | Cuba - Premium Admin Template',
            },
          },
          {
            path: 'list',
            name: 'usersList',
            component: userCards,
            meta: {
              title: 'Users Cards | Cuba - Premium Admin Template',
            },
          },
        ],
      },
      {
        path: '/subjects',
        name: 'subjects',
        children: [
          {
            path: 'list',
            name: 'subjectsList',
            component: subjectsList,
            meta: {
              title: 'Users Cards | Cuba - Premium Admin Template',
            },
          },
        ],
      },
      {
        path: '/subjects-modules',
        name: 'modules',
        children: [
          {
            path: 'list',
            name: 'modulesList',
            component: modulesList,
            meta: {
              title: 'Users Cards | Cuba - Premium Admin Template',
            },
          },
        ],
      },
    ],
  },
  {
    path: '/test-candidate/:token?',
    name: 'testCandidate',
    component: StartTest,
  },
  {
    path: '/test-started/:idTemplate?/:token?',
    name: 'loadTemplate',
    component: loadTemplate,
  },
  {
    path: '/test-candidate/show/:idTemplate?/:token?',
    name: 'testCandidateShow',
    component: loadTemplate,
  },
  {
    path: '/test-candidate/expiredToken/:token?',
    name: 'expiredToken',
    component: expiredToken,
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  base: '/',
  mode: 'history',
  linkActiveClass: 'active',
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach(AuthGuard);
export default router;
