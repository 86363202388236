<template>
  <div class="modal fade modal-bookmark sendInvitation_test" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header py-3">
          <h5 class="modal-title">Send invitaion to pass level test</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h5 class="mb-3"><strong>You are sure to send an invitation to :</strong></h5>
          <p>
            <strong>Name : {{ candidate.first_name }} {{ candidate.last_name }}</strong
            ><br />
            <strong>E-mail : {{ candidate.email }}</strong>
          </p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" @click="sendInvitation" :disabled="isSending">
            <vue-feather class="me-2" type="send" size="16"></vue-feather> Send
          </button>
          <button class="btn btn-primary ms-2" type="button" ref="Close" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'InvitCandidate',
  data() {
    return {
      isSending: false,
    };
  },
  computed: {
    ...mapGetters(['selectedCandidate', 'selectedTestCandidate']),
    candidate() {
      return this.selectedCandidate;
    },
  },
  methods: {
    ...mapActions(['sendInvitationCandidate']),
    sendInvitation() {
      this.isSending = true;
      this.sendInvitationCandidate(this.selectedTestCandidate.id).then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.$toast.show('Email sent successfully', {
            theme: 'outline',
            type: 'success',
          });
        } else {
          this.$toast.show('Error ! Please try again later or contact the administrator', {
            theme: 'outline',
            type: 'error',
          });
        }
        this.$refs.Close.click();
        this.isSending = false;
      });
    },
  },
};
</script>
