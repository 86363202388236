<template>
  <div class="col-xxl-auto col-xl-3 col-sm-6 box-col-6">
    <div class="row">
      <div class="col-xl-12" v-for="puechaseSales in puechaseSalesDetails1" :key="puechaseSales">
        <div class="card widget-1">
          <div class="card-body">
            <div class="widget-content">
              <div :class="puechaseSales.widgetClass">
                <div class="bg-round">
                  <svg class="svg-fill">
                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${puechaseSales.svgIcon1}`"></use>
                  </svg>
                  <svg class="half-circle svg-fill">
                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${puechaseSales.svgIcon2}`"></use>
                  </svg>
                </div>
              </div>
              <div>
                <h4>{{ puechaseSales.number }}</h4>
                <span class="f-light">
                  {{ puechaseSales.title }}
                </span>
              </div>
            </div>
            <div :class="puechaseSales.growthClass">
              <i :class="puechaseSales.iconClass"></i><span>{{ puechaseSales.growthNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xxl-auto col-xl-3 col-sm-6 box-col-6">
    <div class="row">
      <div class="col-xl-12" v-for="puechaseSales in puechaseSalesDetails2" :key="puechaseSales">
        <div class="card widget-1">
          <div class="card-body">
            <div class="widget-content">
              <div :class="puechaseSales.widgetClass">
                <div class="bg-round">
                  <svg class="svg-fill">
                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${puechaseSales.svgIcon1}`"></use>
                  </svg>
                  <svg class="half-circle svg-fill">
                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${puechaseSales.svgIcon2}`"></use>
                  </svg>
                </div>
              </div>
              <div>
                <h4>{{ puechaseSales.number }}</h4>
                <span class="f-light">
                  {{ puechaseSales.title }}
                </span>
              </div>
            </div>
            <div :class="puechaseSales.growthClass">
              <i :class="puechaseSales.iconClass"></i><span>{{ puechaseSales.growthNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-xxl-auto col-xl-3 col-sm-6 box-col-6">
    <div class="row">
      <div class="col-xl-12" v-for="puechaseSales in puechaseSalesDetails3" :key="puechaseSales">
        <div class="card widget-1">
          <div class="card-body">
            <div class="widget-content">
              <div :class="puechaseSales.widgetClass">
                <div class="bg-round">
                  <svg class="svg-fill">
                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${puechaseSales.svgIcon1}`"></use>
                  </svg>
                  <svg class="half-circle svg-fill">
                    <use :xlink:href="require('@/assets/svg/icon-sprite.svg') + `#${puechaseSales.svgIcon2}`"></use>
                  </svg>
                </div>
              </div>
              <div>
                <h4>{{ puechaseSales.number }}</h4>
                <span class="f-light">
                  {{ puechaseSales.title }}
                </span>
              </div>
            </div>
            <div :class="puechaseSales.growthClass">
              <i :class="puechaseSales.iconClass"></i><span>{{ puechaseSales.growthNumber }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { puechaseSalesDetails1, puechaseSalesDetails2, puechaseSalesDetails3 } from '../../data/default';
export default {
  props: ['statsData'],
  data() {
    return {
      puechaseSalesDetails1: puechaseSalesDetails1,
      puechaseSalesDetails2: puechaseSalesDetails2,
      puechaseSalesDetails3: puechaseSalesDetails3,
    };
  },
  mounted() {
    this.puechaseSalesDetails1[0].number = this.statsData.questions_count;
    this.puechaseSalesDetails1[1].number = this.statsData.tests_count;
    this.puechaseSalesDetails2[0].number = this.statsData.passed_tests;
    this.puechaseSalesDetails2[1].number = this.statsData.pending_tests;
    this.puechaseSalesDetails3[0].number = this.statsData.modules_count;
    this.puechaseSalesDetails3[1].number = this.statsData.candidates_count;
  },
};
</script>
