import { dashboardService } from '../../_services';

const state = {
  isLoadDashboard: false,
};
// getters
const getters = {
  isLoadDashboard: (state) => state.isLoadDashboard,
};
const actions = {
  getStatisticsData({ commit }) {
    commit('setLoadingDashboard', true);
    return dashboardService.getStatistics().then(
      (stats) => {
        commit('setLoadingDashboard', false);
        return stats.data;
      },
      (error) => {
        console.error(error);
      }
    );
  },
};
const mutations = {
  setLoadingDashboard(state, load) {
    state.isLoadDashboard = load;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
