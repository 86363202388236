<template>
  <div class="modal fade modal-bookmark" id="addCandidate" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header py-3">
          <h5 class="modal-title">Add module</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="form-bookmark needs-validation">
            <div class="row mb-2">
              <div class="col">
                <div v-if="errors.length">
                  <b>Please correct the following error(s):</b>
                  <ul class="txt-danger">
                    <li v-for="(error, index) in errors" :key="index">{{ error }}</li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row mb-2">
              <div class="form-group col-md-6">
                <label>Name</label>
                <input class="form-control" type="text" autocomplete="off" v-model="moduleSubject.name" />
              </div>
              <div class="form-group col-md-6 mb-0">
                <label>Type</label>
                <select class="form-control form-select" v-model="moduleSubject.subject_id">
                  <option v-for="(item, index) in listSubjects" :key="index" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" @click="checkForm">Save</button>
          <button class="btn btn-primary ms-2" ref="Close" type="button" data-bs-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
  name: 'AddModule',
  props: ['listSubjects'],
  data() {
    return {
      moduleSubject: {},
      errors: [],
    };
  },
  methods: {
    ...mapActions(['addModule']),
    checkForm: function (e) {
      if (this.moduleSubject.name) {
        this.addModule(this.moduleSubject).then((res) => {
          this.$refs.Close.click();
        });
        return true;
      }

      this.errors = [];

      if (!this.moduleSubject.name) {
        this.errors.push('Name required.');
      }

      e.preventDefault();
    },
  },
};
</script>
