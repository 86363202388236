<template>
  <li class="profile-nav onhover-dropdown pe-0 py-0">
    <div class="media profile-media">
      <img
        class="b-r-10"
        :src="user.user.avatar ? baseURL + user.user.avatar : getImageUrl('user/user_avatar.jpg')"
        alt=""
        width="35"
      />
      <div class="media-body">
        <span>{{ user.user.name }}</span>
        <p class="mb-0 font-roboto">{{ user.user.roles[0].name }} <i class="middle fa fa-angle-down"></i></p>
      </div>
    </div>
    <ul class="profile-dropdown onhover-show-div">
      <li><vue-feather type="user"></vue-feather><span>Account </span></li>
      <li><vue-feather type="settings"></vue-feather><span>Settings</span></li>
      <li>
        <a @click="logout"><vue-feather type="log-in"></vue-feather><span>Log out</span></a>
      </li>
    </ul>
  </li>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { baseURL } from '@/constants/config';
import getImage from '@/mixins/getImage';

export default {
  name: 'Profile',
  mixins: [getImage],
  data() {
    return {
      baseURL: baseURL,
    };
  },

  methods: {
    ...mapActions(['logoutUser']),
    logout: function () {
      this.logoutUser();
    },
  },

  computed: {
    ...mapGetters(['user']),
  },
};
</script>
