import { API } from '../modules/axios';

const END_POINT = 'subjects';

const getAllSubjects = (params) => API.get(END_POINT, params);
const addNewSubject = (params) => API.post(`${END_POINT}`, params);
const updateSubject = (idSubject, params) => API.put(`${END_POINT}/${idSubject}`, params);
const deleteSubject = (idSubject) => API.delete(`${END_POINT}/${idSubject}`);

export const subjectsService = {
  getAllSubjects,
  updateSubject,
  deleteSubject,
  addNewSubject,
};
