<template>
  <div class="modal fade modal-bookmark" id="addSubQuestion" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header py-3">
          <h5 class="modal-title">Add Question</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="form-bookmark needs-validation">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Title</label>
                <input class="form-control" type="text" autocomplete="off" v-model="subQuestion.title" />
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-6 mb-0 mt-2">
                <label>Score</label>
                <input class="form-control" type="number" autocomplete="off" v-model="subQuestion.question_score" />
              </div>
              <div class="form-group col-md-6 mb-0 mt-2">
                <label>Type</label>
                <select class="form-control form-select" v-model="subQuestion.question_type_id">
                  <option v-for="(item, index) in questionTypes" :key="index" :value="item.id">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="form-group col-md-10 mb-0 mt-2">
                <label
                  >Answers
                  <button class="btn btn-warning btn-sm px-2 py-0 mt-0 me-2" type="button" @click="addAnswer()">
                    <i class="fa fa-plus"></i>
                  </button>
                  <button
                    v-if="subQuestion.answers.length > 1"
                    class="btn btn-danger btn-sm px-2 py-0 mt-0"
                    @click="removeAnswer($event)"
                  >
                    <i class="fa fa-minus"></i></button
                ></label>
                <div
                  class="form-row d-flex gap-3 align-items-center mb-2"
                  v-for="(item, index) in subQuestion.answers"
                  :key="index"
                >
                  <input class="form-control col-md-10" type="text" autocomplete="off" v-model="item.answer" />
                  <div class="col-md-2">
                    <label :for="'answer_' + item.id" class="d-block mb-0 text-success">
                      <input
                        :id="'answer_' + item.id"
                        class="checkbox_animated"
                        type="checkbox"
                        :value="item.id"
                        v-model="subQuestion.correct_answers"
                      />Correct
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <button class="btn btn-secondary" type="button" @click="submitSubQuest">Save</button>
            <button class="btn btn-primary ms-2" ref="Close" type="button" data-bs-dismiss="modal">Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['questionData', 'questionTypes'],
  data() {
    return {
      subQuestion: {
        answers: [{ id: 1, answer: '' }],
        correct_answers: [],
        question_score: 0,
        question_type_id: '',
        title: '',
      },
    };
  },
  methods: {
    addAnswer() {
      this.subQuestion.answers.push({
        id: this.subQuestion.answers.length + 1,
        answer: '',
      });
    },
    removeAnswer(e) {
      e.preventDefault();
      this.subQuestion.answers.pop();
    },
    resetSubQuestion() {
      this.subQuestion = {
        answers: [{ id: 1, answer: '' }],
        correct_answers: [],
        question_score: 0,
        question_type_id: '',
        title: '',
      };
    },
    submitSubQuest() {
      this.$emit('saveSubQuestion', this.subQuestion);
      this.resetSubQuestion();
      this.$refs.Close.click();
    },
  },
  created() {
    this.subQuestion.order = this.questionData?.sub_question?.length + 1;
  },
};
</script>
