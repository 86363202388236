<template>
  <component
    v-if="!isloading && !isFinished"
    :is="optionComponent"
    :questions="testData"
    :isActiveAnswer="isActiveAnswer"
    :startTimer="startTimer"
    @finishTest="setFinishTest"
  />
  <!-- <resultsTest v-else-if="isFinished" :testResults="results" :totalTime="testData.timer" :isAdmin="isAdminView" /> -->
</template>

<script>
import { defineAsyncComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import resultsTest from './resultsTest.vue';

export default {
  components: {
    resultsTest,
  },
  data() {
    return {
      token: '',
      fullTestsData: {},
      testData: {},
      isActiveAnswer: [],
      startTimer: false,
      isloading: false,
      results: {},
      isFinished: false,
      isAdminView: false,
      activeTestIndex: 0,
      idTemplate: '',
      refreshKey: 0,
    };
  },
  methods: {
    ...mapActions([
      'getQuestionsForFront',
      'getQuestions',
      'getQuestionsResult',
      'updateTokenTestForFront',
      'getQuestionsResultBack',
      'startTestForFront',
      'geResultsForFront',
    ]),
    async forceRerender() {
      this.isloading = true;
      await this.$nextTick();
      this.isloading = false;
    },
    setFinishTest() {
      this.startTimer = false;
      let responseTest = {
        module_id: this.fullTestsData[this.activeTestIndex].module_id,
        level_id: this.fullTestsData[this.activeTestIndex].level_id,
        questions: this.isActiveAnswer,
        end_time: this.testTimer,
      };

      if (this.activeTestIndex < this.fullTestsData.length - 1) {
        this.forceRerender();
        this.activeTestIndex += 1;
        this.initQuestions(this.fullTestsData[this.activeTestIndex]);
        this.startTimer = true;
        this.sendResponseTest(responseTest);
      } else {
        this.sendResponseTest(responseTest);
        this.updateTokenTestForFront(this.token);
        setTimeout(() => {
          this.isFinished = true;
          this.$router.push({ name: 'expiredToken', params: { token: this.token } });
        }, 500);
      }
    },
    sendResponseTest(responseTest) {
      if (this.isAdminView) {
        this.getQuestionsResultBack({ idTest: this.token, params: responseTest });
      } else {
        this.getQuestionsResult({ idTest: this.token, params: responseTest });
      }
    },
    initQuestions(test) {
      if (test) {
        this.idTemplate = test.template.id;
        this.refreshKey++;
        this.isActiveAnswer = [];
        this.testData = test;
        if (this.testData?.sub_questions) {
          this.testData.sub_questions.map((quest) => {
            this.isActiveAnswer.push({
              question_id: quest.id,
              checked_answers: [],
            });
          });
        }
      }
    },
    viewQuestion() {
      this.getQuestions(this.token).then((res) => {
        this.fullTestsData = res.data;
        this.initQuestions(this.fullTestsData[this.activeTestIndex]);
        this.startTimer = true;
        this.isloading = false;
      });
    },
    getQuestionsData() {
      this.getQuestionsForFront(this.token)
        .then((res) => {
          this.fullTestsData = res.data;
          this.initQuestions(this.fullTestsData[this.activeTestIndex]);
          this.startTimer = true;
          this.isloading = false;
          this.startTestForFront(this.token);
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.token = this.$route.params.token;
    this.idTemplate = this.$route.params.idTemplate;
    if (this.token) {
      this.isloading = true;
      if (this.$route.path.includes('show')) {
        this.viewQuestion();
        this.isAdminView = true;
      } else {
        this.getQuestionsData();
      }
    }
  },
  computed: {
    ...mapGetters(['testTimer']),
    optionComponent() {
      this.refreshKey;
      return defineAsyncComponent(() => import(`./templates/template${this.idTemplate}.vue`));
    },
  },
};
</script>
<style lang="scss">
// Mobile first Style

.front-template {
  .module-title {
    font-size: 20px;
    margin: 1rem 0;
  }
  .wrapper {
    width: 100%;
    min-height: 100vh;
    background-image: url('../../assets/images/front/bg_0.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    overflow: hidden;
  }

  .multisteps_form {
    max-width: 88.125rem;
    min-height: 40.4375rem;
    background-repeat: no-repeat;
    background-position: center top;
    background-color: #ffffff;
    background-size: 88.125rem 12.5rem;
    margin: 0rem auto 1rem;
    -webkit-box-shadow: 0 -3em 3em rgba(73, 73, 73, 0.1), 0.3em 0.3em 1em rgba(73, 73, 73, 0.1);
    box-shadow: 0 -3em 3em rgba(73, 73, 73, 0.1), 0.3em 0.3em 1em rgba(73, 73, 73, 0.1);
  }

  .question_title h1 {
    font-size: 0.8rem;
    font-weight: 800;
    padding: 1rem 0.8rem;
    color: #222222;
    border: 1px solid #2894b7;
    border-bottom: 2px solid #2894b7;
    border-radius: 0.3125rem;
    background-color: #ffffff;
  }

  header {
    max-width: 88.125rem;
    margin: auto;
  }
  .logo_area {
    text-align: left;
    padding-left: 0rem;
    img {
      max-height: 100px;
    }
  }

  .row {
    li {
      width: 80%;
      color: #464646;
      font-size: 0.8rem;
      line-height: 1rem;
      margin: auto;
      padding: 0.5rem;
      font-weight: 800;
      cursor: pointer;
      border: 2px solid #d2d2d2;
      border-radius: 0.3125rem;
      margin-bottom: 1rem;

      &:after {
        content: '';
        top: 50%;
        left: -2px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        position: absolute;
        height: 2rem;
        width: 2rem;
        border-radius: 0.3125rem;
        background-color: #2894b724;
      }

      &::before {
        content: '\2713';
        top: 50%;
        left: 6px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 1.4375rem;
        line-height: 1;
        font-weight: 400;
        display: none;
        color: #fff;
        z-index: 1;
        font-family: 'Font Awesome 5 Pro';
        position: absolute;
      }

      &.active {
        color: #2894b7;
        border: 2px solid #2894b7;
        border-bottom: 3px solid #2894b7;
        border-radius: 0.3125rem;
        background-color: #2894b724;

        &:after {
          background: -webkit-gradient(linear, left top, right top, from(#27a5b6), to(#2894b7));
          background: linear-gradient(to right, #27a5b6, #2894b7);
        }

        &:before {
          display: block;
          z-index: 2;
        }

        span {
          display: none;
        }
      }

      &:nth-of-type(even) {
        margin-right: 0;
      }

      label {
        float: left;
        padding-left: 2.375rem;
        cursor: pointer;
        margin: 0;
      }

      span {
        top: 50%;
        left: 10px;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        z-index: 1;
      }

      input {
        display: none;
      }
    }
  }

  .progress {
    max-width: 21.875rem;
    margin: 0 auto;
  }
  .progress .progress-bar {
    background: -webkit-gradient(linear, left top, right top, from(#18eaf9), to(#2894b7)) !important;
    background: linear-gradient(to right, #18eaf9, #2894b7) !important;
  }

  .step_progress {
    bottom: 50px;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
  .step_progress span {
    color: #464646;
    font-size: 0.8rem;
    font-weight: 800;
  }

  .f_btn {
    font-size: 0.7rem;
    padding: 0.3375rem 0.8rem;
    font-weight: 700;
    padding: 0.7375rem 1.2625rem;
    outline: none;
    border: none;
    background: -webkit-gradient(linear, left top, right top, from(#18eaf9), to(#2894b7));
    background: linear-gradient(to right, #18eaf9, #2894b7);
  }
  .f_btn:hover {
    color: #ffffff;
    background: -webkit-gradient(linear, left top, right top, from(#2894b7), to(#18eaf9));
    background: linear-gradient(to right, #18eaf9, #2894b7);
    -webkit-transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
    transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  }
}

@media screen and (max-width: 576px) {
  .front-template .multisteps_form {
    background-image: none !important;
  }
  .question_title h1 {
    margin-top: 0 !important;
  }
}

@media screen and (min-width: 767px) {
  .front-template {
    .module-title {
      font-size: 2rem;
    }
    .logo_area img {
      max-height: 130px;
    }

    .question_title h1 {
      font-size: 1.3875rem;
      padding: 2rem 1rem;
    }

    .row {
      li {
        width: 40%;
        font-size: 1rem;
        line-height: 1.2rem;
        margin-right: 1.5625rem;
        padding: 1rem;
        &:after {
          left: 15px;
          height: 2.3rem;
          width: 2.3rem;
        }

        &::before {
          left: 27px;
          font-size: 1.4375rem;
        }
        span {
          left: 28px;
        }
      }
    }
    .f_btn {
      font-size: 1.125rem;
      font-weight: 800;
      padding: 0.9375rem 1.5625rem;
    }
  }
}

@media screen and (min-width: 992px) {
  .front-template {
    .row {
      li {
        font-size: 1.375rem;
        line-height: 1.6rem;
        padding: 1.5rem;
        &:after {
          height: 2.5rem;
          width: 2.5rem;
        }
      }
    }
    .question_title h1 {
      font-size: 1.6875rem;
      padding: 2.5rem 1rem;
    }

    .step_progress {
      bottom: 30px;
    }

    .step_progress span {
      font-size: 1.375rem;
    }
  }
}
</style>
