<template>
  <Breadcrumbs main="Candidates" title="Candidate List" />

  <div class="container-fluid">
    <div class="row project-cards">
      <topMenu />
    </div>
  </div>
  <EditCandidate />
  <InvitCandidate />
  <InvitMultiCandidate :modules="allModules" @refreshListCandidate="refreshCandidates" />
  <AssignTest v-if="!isLoadModules" :modules="allModules" @refreshListCandidate="refreshCandidates" />
  <div
    class="modal fade modal-deleteCandidate"
    tabindex="-1"
    role="dialog"
    aria-labelledby="myLargeModalLabel"
    aria-hidden="true"
  >
    <validation-modal
      :title="'Delete'"
      :message="'Voulez vous vraiment supprimer cet élément ?'"
      @validationModal="deleteCandidateValidation"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import topMenu from './candidateslist/topMenu.vue';
import validationModal from '@/components/validationModal.vue';
import EditCandidate from './editCandidate.vue';
import AssignTest from './assignTest.vue';
import InvitCandidate from './invitCandidateModal.vue';
import InvitMultiCandidate from './invitMultiCandidateModal.vue';

export default {
  name: 'candidatesList',
  components: {
    topMenu,
    validationModal,
    EditCandidate,
    AssignTest,
    InvitCandidate,
    InvitMultiCandidate,
  },
  data() {
    return {
      tab: [
        {
          type: 'allPage',
          name: 'All',
          active: true,
          icon: 'target',
          id: 'top-home',
          id1: 'top-tabContent',
          label: 'top-home-tab',
        },
        {
          type: 'doingPage',
          name: 'Doing',
          active: false,
          icon: 'info',
          id: 'top-profile',
          label: 'profile-top-tab',
        },
        {
          type: 'donePage',
          name: 'Done',
          active: false,
          icon: 'check-circle',
          id: 'top-contact',
          label: 'contact-top-tab',
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['selectedCandidate', 'allModules', 'isLoadModules']),
  },
  methods: {
    ...mapActions(['getAllCandidates', 'getAllModules', 'deleteCandidate']),
    refreshCandidates() {
      this.getAllCandidates();
    },
    deleteCandidateValidation(validation) {
      if (validation) {
        this.deleteCandidate(this.selectedCandidate.id).then(() => {
          this.$toast.show('Delete Success', {
            theme: 'outline',
            type: 'warning',
          });
        });
      }
    },
  },
  created() {
    this.getAllCandidates();
    this.getAllModules();
  },
};
</script>
<style>
.tests-box-list {
  max-height: 180px;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
}
</style>
