<template>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content">
      <div class="modal-header py-3">
        <h5 class="modal-title">{{ title }}</h5>
        <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <form class="form-bookmark needs-validation" method="post">
          <p>{{ message }}</p>
          <br />
        </form>
      </div>
      <div class="modal-footer">
        <button
          class="btn btn-secondary"
          type="button"
          @click="$emit('validationModal', true)"
          data-bs-dismiss="modal">
          Yes
        </button>
        <button
          class="btn btn-primary ms-2"
          type="button"
          @click="$emit('validationModal', false)"
          data-bs-dismiss="modal"
        >
          No
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "validationModal",
  props: ['title', 'message']

}
</script>