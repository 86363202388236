<template>
  <div class="modal fade modal-bookmark assign-test-toCandidate" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header py-3">
          <h5 class="modal-title">Assign Test</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="form-bookmark needs-validation">
            <div v-for="(item, index) in countTests" :key="index" class="row mb-2">
              <div class="form-group col-md-6">
                <label>Modules</label>
                <select class="form-select" v-model="selectedModule[index]">
                  <option v-for="(item, index) in modules" :key="index" :value="item">
                    {{ item.name }}
                  </option>
                </select>
              </div>
              <div class="form-group col-md-6">
                <label>Test</label>
                <select class="form-select" v-model="selectedLevel[index]">
                  <option v-for="(item, index) in selectedModule[index]?.levels" :key="index" :value="item">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row">
              <div class="col d-flex justify-content-end">
                <button class="btn btn-xs btn-warning" @click="addTestCandidate">
                  <vue-feather class="me-1" size="14" type="plus-square"> </vue-feather> Add test
                </button>
              </div>
            </div>

            <div class="row">
              <div class="col-md-4">
                <label>Opening Date & Time</label>
                <input
                  class="form-control digits"
                  id="example-datetime-local-input"
                  type="datetime-local"
                  v-model="openingDate"
                />
              </div>
              <div class="col-md-4">
                <label>Limit Date & Time</label>
                <input
                  class="form-control digits"
                  id="example-datetime-local-input"
                  type="datetime-local"
                  v-model="limitDate"
                />
              </div>
            </div>
            <br />
            <button class="btn btn-secondary" type="button" @click="checkForm">Save</button>
            <button class="btn btn-primary ms-2" ref="Close" type="button" data-bs-dismiss="modal">Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'AssignTest',
  props: ['modules'],
  data() {
    return {
      countTests: 1,
      selectedModule: [],
      selectedLevel: [],
      errors: [],
      openingDate: moment().format('YYYY-MM-DD HH:MM'),
      limitDate: moment().format('YYYY-MM-DD HH:MM'),
    };
  },
  computed: {
    ...mapGetters(['selectedCandidate']),
    candidateData() {
      return this.selectedCandidate;
    },
  },
  methods: {
    ...mapActions(['assignTestToCandidate']),
    resetForm() {
      this.countTests = 1;
      this.selectedModule = [];
      this.selectedLevel = [];
    },
    addTestCandidate(e) {
      e.preventDefault();
      this.countTests += 1;
    },
    checkForm: function (e) {
      let tests = this.selectedModule.map((module, index) => {
        return {
          module_id: module.id,
          level_id: this.selectedLevel[index].id,
        };
      });

      let params = {
        tests,
        candidate_ids: [this.selectedCandidate.id],
        send_invitation: false,
        opening_date: this.openingDate,
        limit_date: this.limitDate,
      };
      this.assignTestToCandidate(params).then((res) => {
        if (res.status === 200 || res.status === 201) {
          this.$toast.show(this.checkedSendEmail ? 'Email sent successfully' : 'Assign test successfully', {
            theme: 'outline',
            type: 'success',
          });
          this.$emit('refreshListCandidate');
        } else {
          this.$toast.show('Error ! Please try again later or contact the administrator', {
            theme: 'outline',
            type: 'error',
          });
        }
        this.resetForm();
        this.$refs.Close.click();
      });
      return true;
    },
  },
};
</script>
