<template>
  <div class="modal fade modal-bookmark" id="createModuleLevel" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header py-3">
          <h5 class="modal-title"> {{ isEditModal ? 'Edit Test' : 'ADD Test'}}</h5>
          <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <form class="form-bookmark needs-validation" method="post">
            <div class="form-row">
              <div class="form-group col-md-12">
                <label>Name</label>
                <input class="form-control" type="text" autocomplete="off" v-model="selectedLevel.name" />
              </div>
              <div class="form-group col-md-12 mb-0 mt-2">
                <label>Score</label>
                <input class="form-control" type="number" v-model="selectedLevel.score" />
              </div>
            </div>
            <br />
            <button class="btn btn-secondary" type="submit" @click="submitForm">Save</button>
            <button class="btn btn-primary ms-2" ref="Close" type="button" data-bs-dismiss="modal">Cancel</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: ['module'],
  data() {
    return {
      levelData: {},
    };
  },
  computed: {
    ...mapGetters(['selectedLevel']),
    isEditModal() {
      if (this.selectedLevel.id) {
        this.levelData = this.selectedLevel;
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    ...mapActions(['addLevelToModule', 'updateLevel']),
    submitForm(e) {
      e.preventDefault();
      this.selectedLevel.keyModule = this.module.key;
      if (this.isEditModal) {
        this.updateLevel(this.selectedLevel).then((res) => {
          if (res) {
            this.$toast.show('Update Success', {
              type: 'success',
              duration: 3000,
            });
          }
        });
      } else {
        this.addLevelToModule(this.selectedLevel).then((res) => {
          if (res.status === 200 || res.status === 201) {
            this.$toast.show('Add Success', {
              type: 'success',
              duration: 3000,
            });
          }
        });
      }
      this.$refs.Close.click();
    },
  },
};
</script>
