<template>
  <div class="container show-score">
    <div v-if="testResults" class="multisteps_form overflow-hidden position-relative text-center pt-5">
      <div v-if="testResults.teacher_logo" class="logo_area">
        <img :src="baseURL + testResults.teacher_logo" alt="Logo" />
      </div>
      <div class="row show-score__block mb-3">
        <div class="col">
          <div v-if="testResults.candidate_score > testResults.total_score / 2">
            <font-awesome-icon class="smiley-ico text-success" :icon="['fas', 'fa-smile']" />
          </div>
          <div v-else-if="testResults.candidate_score == testResults.total_score / 2">
            <font-awesome-icon class="smiley-ico text-warning" :icon="['fas', 'fa-meh']" />
          </div>
          <div v-else>
            <font-awesome-icon class="smiley-ico text-danger" :icon="['fas', 'fa-frown']" />
          </div>
        </div>
      </div>
      <div class="row show-score__block mb-3">
        <div class="col-6">
          <h3 class="show-score__sub-title text-end mb-2">
            <font-awesome-icon class="me-2" :icon="['fas', 'clipboard-check']" />Score
          </h3>
          <h3 class="show-score__sub-title text-end mb-2">
            <font-awesome-icon class="me-2" :icon="['fas', 'clock']" />Timing
          </h3>
          <h3 v-if="testResults.level" class="show-score__sub-title text-end m-0">
            <font-awesome-icon class="me-2" :icon="['fas', 'square-poll-vertical']" />Level
          </h3>
        </div>
        <div class="col-6">
          <h2 class="show-score__sub-title text-start mb-2">
            <span>{{ testResults.candidate_score }}</span> / {{ testResults.total_score }}
          </h2>
          <h2 class="show-score__sub-title text-start">
            <span
              >{{ secondsToMinutes(totalSecondTests) }} / {{ secondsToMinutes(testResults.total_time) }} min</span
            >
          </h2>
          <h2 v-if="testResults.level" class="show-score__sub-title text-start m-0">{{ testResults.level }}</h2>
        </div>
      </div>
      <div class="row show-score__block mb-3">
        <div class="col">
          <div class="row" v-for="(testModule, index) in testResults.modules" :key="index">
            <div class="col-6 ms-auto">
              <h3 class="show-score__title text-end">
                <span class="text-primary">{{ testModule.module }} </span>
              </h3>
            </div>
            <div class="col-6 me-auto">
              <h3 class="text-start">{{ testModule.score ? testModule.score : 0 }} / {{ testModule.total_score }}</h3>
            </div>
          </div>
        </div>
      </div>

      <div class="row show-score__info">
        <div class="col">
          <p>Si vous avez des questions veuillez contacter votre administartion</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { baseURL } from '@/constants/config';

export default {
  data() {
    return {
      testResults: '',
      baseURL: baseURL,
    };
  },
  methods: {
    ...mapActions(['geResultsForFront']),
    secondsToMinutes(seconds) {
      return Math.floor(seconds / 60);
    },
  },
  computed: {
    totalSecondTests() {
      if (this.testResults.modules) {
        let totalSecond = 0;
        this.testResults.modules.map((res) => {
          totalSecond += res.end_time;
        });
        return this.testResults.total_time - totalSecond;
      }
    },
    percentageScore() {
      if (this.testResults.total_score) {
        return Math.round((this.testResults.candidate_score / this.testResults.total_score) * 100);
      }
    },
  },
  created() {
    const token = this.$route.params.token;
    if (token) {
      this.geResultsForFront(token).then((res) => {
        this.testResults = res;
      });
    }
  },
};
</script>
<style scoped lang="scss">
.show-score {
  &__block {
    background-color: #f0f0f0;
    border-radius: 15px;
    padding: 1rem;
    max-width: 700px;
    margin: auto;
  }
  &__info {
    max-width: 500px;
    margin: auto;
  }
  &__title {
    font-size: 1.4rem;
  }
  &__sub-title {
    font-size: 1.3rem;
  }
}

.logo_area img {
  max-height: 150px;
}

.smiley-ico {
  font-size: 5rem;
}

.wrapper {
  width: 100%;
  min-height: 100vh;
  background-image: url('../../../assets/images/front/bg_0.png');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}

.count_box {
  max-width: 25rem;
  min-height: 5.625rem;
  background: -webkit-gradient(linear, left top, right top, from(#18eaf9), to(#2894b7));
  background: linear-gradient(to right, #18eaf9, #2894b7);
  font-family: 'Jost', sans-serif;
}

.count_clock img {
  width: 2.5rem;
}

.count_title h4 {
  color: #000a38;
  font-size: 1.146875rem;
  font-weight: 800;
  margin-bottom: -0.4rem;
}
.count_title span {
  color: #000a38;
  font-size: 1.146875rem;
  font-weight: 800;
}

.count_number {
  width: 14rem;
  height: 4.5rem;
  background-color: #ffffff;
  margin-right: -0.4rem;
}
.count_number .count_hours h3,
.count_number .count_min h3,
.count_number .count_sec h3 {
  color: #000a38;
  font-size: 1.75rem;
  font-weight: 800;
  margin-bottom: -0.4rem;
}
.count_number .count_hours span,
.count_number .count_min span,
.count_number .count_sec span {
  color: #000a38;
  font-size: 1rem;
  font-weight: 800;
  padding-left: 0.2rem;
}
.count_number .count_hours:after,
.count_number .count_min:after,
.count_number .count_sec:after {
  content: '';
  width: 0.1875rem;
  height: 2.5625rem;
  top: 50%;
  right: 80px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  background-color: #e1e1e1;
}
.count_number .count_hours:before,
.count_number .count_min:before,
.count_number .count_sec:before {
  content: '';
  width: 0.1875rem;
  height: 2.5625rem;
  top: 50%;
  left: 80px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: absolute;
  background-color: #e1e1e1;
}

.multisteps_form {
  max-width: 88.125rem;
  min-height: 30.4375rem;
  padding: 1rem;
  background-repeat: no-repeat;
  background-position: center top;
  background-color: #ffffff;
  background-size: 88.125rem 16.5rem;
  margin: 2rem auto;
  -webkit-box-shadow: 0 -3em 3em rgba(73, 73, 73, 0.1), 0.3em 0.3em 1em rgba(73, 73, 73, 0.1);
  box-shadow: 0 -3em 3em rgba(73, 73, 73, 0.1), 0.3em 0.3em 1em rgba(73, 73, 73, 0.1);
}

p {
  font-size: 1.3rem;
}

.f_btn {
  font-size: 1.125rem;
  font-weight: 800;
  padding: 0.9375rem 1.5625rem;
  outline: none;
  border: none;
  background: -webkit-gradient(linear, left top, right top, from(#18eaf9), to(#2894b7));
  background: linear-gradient(to right, #18eaf9, #2894b7);
}
.f_btn:hover {
  color: #ffffff;
  background: -webkit-gradient(linear, left top, right top, from(#2894b7), to(#18eaf9));
  background: linear-gradient(to right, #18eaf9, #2894b7);
  -webkit-transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.prev_btn {
  left: 0;
  bottom: 0;
}

.next_btn {
  right: 0;
  bottom: 0;
}

body {
  line-height: 1.65;
  font-style: normal;
  font-display: swap;
  font-family: 'Sen', sans-serif;
}

a,
button,
input {
  cursor: pointer;
  display: inline-block;
}
a:hover,
button:hover,
input:hover {
  text-decoration: none;
}
a:focus,
button:focus,
input:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

img {
  height: auto;
  max-width: 100%;
}

/* css animations - end ============================================= */
/* transition - start =============================================== */
a,
button {
  -webkit-transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
  transition: 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

/* transition - end ================================================= */
.animate_25ms {
  -webkit-animation-delay: 25ms;
  animation-delay: 25ms;
}

.animate_50ms {
  -webkit-animation-delay: 50ms;
  animation-delay: 50ms;
}

.animate_100ms {
  -webkit-animation-delay: 100ms;
  animation-delay: 100ms;
}

.animate_150ms {
  -webkit-animation-delay: 150ms;
  animation-delay: 150ms;
}

.animate_200ms {
  -webkit-animation-delay: 200ms;
  animation-delay: 200ms;
}

@media (min-width: 1500px) {
  .container {
    max-width: 1510px;
  }
}
/* ==================================================
*	01 - media screen and (max-width: 991px)
*	02 - media screen and (max-width: 767px)
*	03 - media screen and (max-width: 575px)
*	04 - media screen and (max-width: 380px)
*	05 - media screen and (max-width: 320px)
*   XX-Large devices (larger desktops)
*   No media query since the xxl breakpoint has no upper bound on its width
================================================== */
@media screen and (max-width: 991.98px) {
  .count_box {
    margin-right: 0rem !important;
  }

  .count_title h4 {
    font-size: 0.9rem;
    font-weight: 600;
  }
  .count_title span {
    font-size: 0.9rem;
    font-weight: 600;
  }

  .count_number {
    width: 12rem;
  }
  .count_number .count_hours h3,
  .count_number .count_min h3,
  .count_number .count_sec h3 {
    font-size: 1.35rem;
    font-weight: 700;
  }
  .count_number .count_hours span,
  .count_number .count_min span,
  .count_number .count_sec span {
    font-size: 1rem;
    font-weight: 700;
    padding-left: 0rem;
  }
  .count_number .count_hours:after,
  .count_number .count_min:after,
  .count_number .count_sec:after {
    right: 66px;
  }
  .count_number .count_hours:before,
  .count_number .count_min:before,
  .count_number .count_sec:before {
    left: 68px;
  }

  .multisteps_form {
    max-width: 86rem;
    min-height: 43rem;
  }

  .f_btn {
    font-size: 1rem;
    font-weight: 700;
    padding: 0.7375rem 1.2625rem;
  }
}
@media screen and (max-width: 767.98px) {
  .count_title h4 {
    font-size: 0.7rem;
    font-weight: 500;
    padding-right: 0.2rem;
  }

  .count_number {
    width: 12rem;
    height: 3.5rem;
  }
  .count_number .count_hours h3,
  .count_number .count_min h3,
  .count_number .count_sec h3 {
    font-size: 1rem;
    font-weight: 600;
  }
  .count_number .count_hours span,
  .count_number .count_min span,
  .count_number .count_sec span {
    font-size: 0.8rem;
    font-weight: 600;
  }
  .count_number .count_hours:after,
  .count_number .count_min:after,
  .count_number .count_sec:after {
    top: 8px;
    right: 51px;
  }
  .count_number .count_hours:before,
  .count_number .count_min:before,
  .count_number .count_sec:before {
    top: 8px;
    left: 48px;
  }

  .multisteps_form {
    max-width: 83rem;
    min-height: 40rem;
  }

  .f_btn {
    font-size: 0.6rem;
    font-weight: 600;
    padding: 0.5375rem 0.4rem;
  }
}
@media screen and (max-width: 575.98px) {
  .multisteps_form {
    max-width: 81rem;
    min-height: 38rem;
  }
}
@media screen and (max-width: 379.98px) {
  .multisteps_form {
    max-width: 79rem;
    min-height: 33rem;
  }

  .step_progress span {
    font-size: 0.7rem;
  }

  .f_btn {
    font-size: 0.5rem;
    padding: 0.3375rem 0.8rem;
  }
}
@media screen and (max-width: 319.98px) {
  .multisteps_form {
    min-height: 32rem;
  }

  .f_btn {
    font-size: 0.5rem;
    padding: 0.3375rem 0.6rem;
  }
}
</style>
