<template>
  <Breadcrumbs main="Tests" title="Question Create" />

  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-body">
            <div class="form theme-form">
              <div class="row">
                <div class="col">
                  <div class="mb-3">
                    <label>Question</label>
                    <input class="form-control" type="text" v-model="questions.title" placeholder="Question *" />
                  </div>
                </div>
                <div class="col-4 d-flex ms-auto align-items-center">
                  <button
                    class="btn btn-md btn-primary ms-auto px-2"
                    href="javascript:void(0)"
                    data-bs-toggle="modal"
                    data-bs-target="#addSubQuestion"
                  >
                    <vue-feather type="plus" size="16"></vue-feather> Add Question
                  </button>
                  <add-sub-question-modal
                    :questionData="questions"
                    :questionTypes="questionTypes"
                    @save-sub-question="addSubQuestionData"
                  />
                  <button class="btn btn-md btn-danger ms-2 px-2" @click="saveQuestion">
                    <vue-feather type="save" size="16"></vue-feather> Save Question
                  </button>
                </div>
              </div>
              <div v-if="questions.description" class="row">
                <div class="col">
                  <div class="mb-3">
                    <label>Text</label>
                    <textarea class="form-control" cols="30" rows="10" v-model="questions.description"></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-sm-3">
                  <div class="mb-3">
                    <label>Categories</label>
                    <select class="form-select" v-model="questions.age_category_id">
                      <option v-for="(item, index) in ageCategories" :key="index" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="mb-3">
                    <label>Score</label>
                    <input class="form-control" type="text" v-model="questions.question_score" placeholder="Score" />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="mb-3">
                    <label>Time (sec)</label>
                    <input class="form-control" type="text" v-model="questions.timer" placeholder="Time" />
                  </div>
                </div>
                <div class="col-sm-3">
                  <div class="mb-3">
                    <label>Template</label>
                    <select class="form-select" v-model="questions.ref_template">
                      <option v-for="(item, index) in allTemplates" :key="index" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="questions.module_id === 3" class="row">
                <div class="col-sm-4">
                  <div class="mb-3">
                    <label>Audio File</label>
                    <input class="form-control" type="file" accept="audio/*" @change="handleFileUpload($event)" />
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="mb-3">
                    <label>Audio File</label>
                    <audio id="audio-preview" controls :src="!soundEdited ? baseURL + questions.sound_file : file" />
                  </div>
                </div>
              </div>
              <!-- <multi-choice v-if="questType == 'multi-choice'" />
              <unique-choice v-if="questType == 'unique-choice'" />
              <long-text v-if="questType == 'long-text'" /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form theme-form">
      <div class="row">
        <div class="col">
          <div class="default-according" id="accordionclose">
            <div class="card" v-for="(question, index) in questions.sub_questions" :key="index" :id="'quest_' + index">
              <div class="card-header bg-secondary" :id="'questHeading_' + index">
                <div
                  class="row align-items-center ps-3"
                  data-bs-toggle="collapse"
                  :data-bs-target="'#collapse_' + index"
                  aria-expanded="true"
                  :aria-controls="'quest_' + index"
                >
                  <label class="col-3 col-form-label">Question : </label>
                  <div class="col">
                    <input
                      class="form-control rounded-0"
                      type="text"
                      v-model="question.title"
                      placeholder="Question *"
                    />
                  </div>
                </div>
              </div>
              <div
                class="card-body collapse"
                :id="'collapse_' + index"
                :aria-labelledby="'quest_' + index"
                data-bs-parent="#accordionclose"
              >
                <div class="row align-items-center mb-4">
                  <div class="col-md-2">
                    <h6>Type</h6>
                  </div>
                  <div class="col-md-4">
                    <select class="form-select" v-model="question.question_type.id">
                      <option v-for="(item, index) in questionTypes" :key="index" :value="item.id">
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <h6>Answers :</h6>
                <div class="col-10">
                  <div
                    class="form-row d-flex gap-3 align-items-center mb-2"
                    v-for="(answer, indexAnswer) in question.answers"
                    :key="'answer_' + indexAnswer"
                  >
                    <input class="form-control col-md-8" type="text" v-model="answer.answer" placeholder="answer *" />
                    <div class="col-md-4 d-flex">
                      <label :for="'answerQuest_' + answer.id" class="d-block mb-0 text-success">
                        <input
                          :id="'answerQuest_' + answer.id"
                          class="checkbox_animated"
                          type="checkbox"
                          :value="answer.id"
                          v-model="question.correct_answers"
                        />Correct
                      </label>
                      <a href="javascript:void(0)" class="px-2 text-danger" @click="removeAnswer(index, indexAnswer)">
                        <vue-feather type="trash" size="16"></vue-feather>
                      </a>
                    </div>
                  </div>
                </div>
                <button class="btn btn-sm btn-primary mx-auto d-flex px-2" type="button" @click="addAnswer(index)">
                  <vue-feather type="plus" size="16"></vue-feather>
                  Add Answer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import multiChoice from '@/components/questions/multiChoice.vue';
import uniqueChoice from '@/components/questions/uniqueChoice.vue';
import longText from '@/components/questions/longText.vue';
import { mapActions, mapGetters } from 'vuex';
import { baseURL } from '@/constants/config';
import addSubQuestionModal from './addSubQuestionModal.vue';
export default {
  components: { multiChoice, uniqueChoice, longText, addSubQuestionModal },
  name: 'createQuest',
  data() {
    return {
      idModule: '',
      idLvl: '',
      baseURL: baseURL,
      questions: {},
      soundEdited: false,
      file: '',
      ageCategories: [
        { id: 1, name: '10_to_16' },
        { id: 2, name: '17_and_above' },
      ],
    };
  },
  computed: {
    ...mapGetters({
      questionTypes: 'questionTypes',
      allTemplates: 'allTemplates',
    }),
  },
  methods: {
    ...mapActions(['getQuestions', 'getQuestionTypes', 'addSubQuestion', 'addParentQuestion', 'getAllTemplates']),
    saveQuestion() {
      this.addParentQuestion(this.questions).then((res) => {
        if (res.status === 200) {
          this.$router.push({ name: 'editQuest', params: { id: res.data.id } });
          this.$toast.show('Add Success', {
            theme: 'outline',
            type: 'success',
          });
        }
      });
    },
    addSubQuestionData(data) {
      data.question_type = {
        id: data.question_type_id,
      };
      this.questions = {
        ...this.questions,
        sub_questions: [...this.questions.sub_questions, data],
      };
      this.$toast.show('Add question succeed but not saved yet !', {
        theme: 'outline',
        type: 'success',
      });
    },
    previewAudio() {
      let audio = document.getElementById('audio-preview');
      let reader = new FileReader();

      reader.readAsDataURL(this.file);
      reader.addEventListener('load', function () {
        audio.src = reader.result;
        this.questions.sound_file = reader.result;
      });
    },
    handleFileUpload(event) {
      this.soundEdited = true;
      this.file = event.target.files[0];
      this.previewAudio();
    },
    addAnswer(indexQuestion) {
      this.questions.sub_questions[indexQuestion].answers.push({
        id: this.questions.sub_questions[indexQuestion].answers.length + 1,
        answer: '',
      });
    },
    removeAnswer(indexQuestion, indexAnswer) {
      this.questions.sub_questions[indexQuestion].answers.splice(indexAnswer, 1);
    },
  },
  created() {
    this.getQuestionTypes();
    this.getAllTemplates();
    this.idModule = this.$route.params.module;
    this.idLvl = this.$route.params.lvl;
    this.questions = {
      level_id: Number(this.idLvl),
      module_id: Number(this.idModule),
      sub_questions: [],
    };
  },
};
</script>
