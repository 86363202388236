import { API } from '../modules/axios';

const END_POINT_QUESTIONS = 'questions';
const END_POINT_QUESTION_TYPES = 'question-types';

const getQuestions = (idQuestions) => API.get(`${END_POINT_QUESTIONS}/${idQuestions}`);
const getQuestionTypes = (params) => API.get(END_POINT_QUESTION_TYPES, params);
const deleteQuestion = (idQuestion) => API.delete(`${END_POINT_QUESTIONS}/${idQuestion}`);
const updateQuestion = (idQuestion, newQuestion) => API.post(`${END_POINT_QUESTIONS}/${idQuestion}`, newQuestion);
const addQuestion = (newQuestion) => API.post(`${END_POINT_QUESTIONS}`, newQuestion);
const sendQuestionsTest = (idQuestion, params) => API.post(`${END_POINT_QUESTIONS}/parent/${idQuestion}`, params);

export const questionsService = {
  getQuestions,
  getQuestionTypes,
  deleteQuestion,
  updateQuestion,
  addQuestion,
  sendQuestionsTest
};
