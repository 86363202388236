<template>
  <div class="card animate-chk">
    <div class="card-header py-3">
      <h5>Add answers and check the correct answer</h5>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col">
          <textarea
            class="form-control"
            id="validationTextarea"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</template>
