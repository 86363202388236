<template>
  <Breadcrumbs main="Tests" :title="`Questions ${title}`" />

  <div class="container-fluid">
    <div class="email-wrap bookmark-wrap">
      <div class="row">
        <fullModule />
      </div>
    </div>
  </div>
</template>

<script>
import fullModule from './fullModule.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    fullModule,
  },
  computed: {
    title() {
      return this.$route.params.type;
    },
  },
  methods: {
    ...mapActions(['getModule']),
  },
  created() {
    const typeModule = this.$route.params.type;
    this.getModule(typeModule);
  },
};
</script>
