import { API, API_FILE_EXPORT } from '../modules/axios';

const END_POINT = 'candidates';

const getAllCandidates = (params) => API.get(END_POINT, params);
const exportCandidatesList = (params) => API_FILE_EXPORT.get(END_POINT, params);
const getResultCandidate = (idTest) => API.get(`${END_POINT}/questions-result/${idTest}`);
const addCandidate = (params) => API.post(END_POINT, params);
const invitCandidate = (candidate_id) => API.post(`${END_POINT}/send-invitation/${candidate_id}`);
const sendInvitationCandidates = (params) => API.post(`${END_POINT}/assign-tests`, params);
const assignTest = (params) => API.post(`${END_POINT}/assign-tests`, params);
const updateCandidate = (candidate_id, params) => API.put(`${END_POINT}/${candidate_id}`, params);
const deleteCandidate = (candidate_id) => API.delete(`${END_POINT}/${candidate_id}`);
const deleteTestCandidate = (question_id) => API.delete(`${END_POINT}/delete-test/${question_id}`);

export const candidatesService = {
  getAllCandidates,
  exportCandidatesList,
  invitCandidate,
  sendInvitationCandidates,
  addCandidate,
  updateCandidate,
  assignTest,
  deleteCandidate,
  deleteTestCandidate,
  getResultCandidate,
};
