import { templatesService } from '../../_services';

const state = {
  isLoadTemplates: false,
  listTemplates: [],
};
// getters
const getters = {
  allTemplates: (state) => state.listTemplates,
  isLoadTemplates: (state) => state.isLoadSubjects,
};
const actions = {
  getAllTemplates({ commit }) {
    templatesService.getTemplates().then(
      (templates) => commit('getAllTemplatesSuccess', templates.data.data),
      (error) => commit('getAllTemplatesFailure', error)
    );
  },
};
const mutations = {
  getAllTemplatesSuccess(state, templates) {
    state.listTemplates = templates;
  },
  getAllTemplatesFailure(state, error) {
    state.errors = { error };
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
