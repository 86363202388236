<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-xl-7 order-1">
        <img class="bg-img-cover bg-center img-fluid" src="../../assets/images/login/1.jpg" alt="looginpage" />
      </div>
      <div class="col-xl-5 p-0">
        <div class="login-card">
          <div>
            <div>
              <a class="logo text-center">
                <img class="img-fluid for-light" src="../../assets/images/logo/english_attack.png" width="250" alt="looginpage" />
                <img class="img-fluid for-dark" src="../../assets/images/logo/english_attack.png" alt="looginpage"
              /></a>
            </div>
            <div class="login-main">
              <b-card no-body class="p-5">
                <b-card-text>
                  <form @keyup.enter="loginUser" class="theme-form">
                    <h4 id="title">Login</h4>
                    <p>Enter your email & password to login</p>
                    <div class="form-group">
                      <label class="col-form-label">Email Address</label>
                      <input
                        data-testid="input-username"
                        id="input-username"
                        v-model="username"
                        class="form-control"
                        type="username"
                        required=""
                        placeholder="trust@trust.com"
                        :class="{
                          'is-invalid': submitted && !username,
                        }"
                      />
                      <div v-show="submitted && !username" class="invalid-feedback">Email is required</div>
                    </div>
                    <div class="form-group">
                      <label class="col-form-label">Password</label>
                      <input
                        id="input-password"
                        v-model="password"
                        autocomplete=""
                        class="form-control"
                        :type="type"
                        name="login[password]"
                        required=""
                        placeholder="*********"
                        :class="{
                          'is-invalid': submitted && !username,
                        }"
                      />
                      <div v-show="submitted && !password" class="invalid-feedback">Email is required</div>
                      <div class="show-hide" @click="showPassword">
                        <span class="show"></span>
                      </div>
                    </div>
                    <div class="form-group mb-0">
                      <div class="checkbox p-0">
                        <input id="checkbox1" type="checkbox" />
                        <label class="text-muted" for="checkbox1">Remember password</label>
                      </div>
                      <button id="btn-sign-in" class="btn btn-primary btn-block" type="button" @click="loginUser">
                        Login
                      </button>
                      <div id="msg-error" v-if="isError">{{ isError }}</div>
                    </div>
                  </form>
                </b-card-text>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import Userauth from "../../auth/js/index";
import { mapActions } from 'vuex';
export default {
  name: 'login',
  data() {
    return {
      type: 'password',
      email: '',
      password: 'password',
      username: 'bizidibrahim1@gmail.com',
      passwordjwt: '',
      submitted: false,
      isError: false,
    };
  },
  computed: {
    loggingIn() {
      return this.$store.state.authentication.status.loggingIn;
    },
  },
  methods: {
    ...mapActions(['login']),
    handleSubmit() {
      this.submitted = true;
      const { username, passwordjwt } = this;
      const { dispatch } = this.$store;
      if (username && passwordjwt) {
        dispatch('authentication/login', { username, passwordjwt });
      }
    },
    // show/hide password
    showPassword: function () {
      if (this.type === 'password') {
        this.type = 'text';
      } else {
        this.type = 'password';
      }
    },
    loginUser() {
      this.submitted = true;
      if (!this.username) {
        this.isError = 'Username is required';
        this.submitted = false;
        return;
      } else if (!this.password) {
        this.isError = 'Password is required';
        this.submitted = false;
        return;
      }
      const userInfo = {
        username: this.username,
        password: this.password,
      };
      this.login(userInfo).then(() => {
        this.submitted = false;
      });
    },
  },
};
</script>
<style scoped>
.container-fluid {
  max-height: 100vh;
  overflow: hidden;
}
</style>
