import { API } from '../modules/axios';
const END_POINT = '/users';

const getAllUsers = (params) => API.get(END_POINT, params);
const getUserById = (user_id) => API.get(`${END_POINT}/${user_id}`);
const deleteUser = (user_id) => API.delete(`${END_POINT}/${user_id}`);
const updateUser = (user_id, params) => API.put(`${END_POINT}/${user_id}`, params);
const addUser = (params) => API.post(`${END_POINT}`, params);
const blockUnblockUser = (user_id) => API.put(`${END_POINT}/update-status/${user_id}`);
const setPasswordUser = (user_id, params) => API.put(`${END_POINT}/${user_id}`, params);

const login = (params) => API.post(`/login`, params);
const logout = () => {
  localStorage.removeItem('user');
  localStorage.removeItem('menu');
  location.reload(true);
};

export const userService = {
  login,
  logout,
  getAllUsers,
  getUserById,
  deleteUser,
  updateUser,
  addUser,
  blockUnblockUser,
  setPasswordUser,
};
