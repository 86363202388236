<template>
  <div>
    <Breadcrumbs main="Dashboard" title="Level Tests" />

    <div class="container-fluid">
      <div v-if="!isLoadDashboard" class="row widget-grid">
        <WelcomeCard />
        <PurchaseSaleCard v-if="!isLoading" :statsData="stats" />
      </div>
    </div>
  </div>
</template>

<script>
import WelcomeCard from './WelcomeCard.vue';
import PurchaseSaleCard from './PurchaseSaleCard.vue';
import { mapGetters, mapActions } from 'vuex';

export default {
  data() {
    return {
      stats: {},
      isLoading: false,
    };
  },
  components: {
    WelcomeCard,
    PurchaseSaleCard,
  },
  computed: {
    ...mapGetters(['isLoadDashboard']),
  },
  methods: {
    ...mapActions(['getStatisticsData']),
  },
  created() {
    this.isLoading = true;
    this.getStatisticsData().then((stats) => {
      this.stats = stats;
      this.isLoading = false;
    });
  },
};
</script>
